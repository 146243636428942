import React, { useEffect, useState } from "react";

import _ from 'underscore';

import { useMediaQuery, useTheme, Box, Card, CardActionArea, Chip, Paper,
  Stack, Typography, Button, Container, Grid2, CardMedia, Divider, Drawer,
  TextField, Link, CardContent, IconButton, InputAdornment, FormControl,
  InputLabel, Select, OutlinedInput, MenuItem, ListSubheader, Popover, Switch,
  AppBar, Toolbar  } from "@mui/material";

  import { alpha, createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';


// Redux
import { useDispatch, useSelector } from "react-redux";


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from "@mui/material/ListItem";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


// Icons
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import InfoIcon from '@mui/icons-material/Info';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import MenuIcon from '@mui/icons-material/Menu';

// redux
import { logOut, updateCategories, updateGeoregions, updateCommunication, resetPw } from "../redux/slices/user-slice";
import { addPaymentMethod } from "../redux/slices/payment-slice";

// Services
import { isEmail, checkPassword, verifyPhoneNumber, showDollars, isNullOrEmpty } from "../services/utilities";

// Components
import { PasswordField } from "../components/LoginRegister";
import StripePayment, { SelectPlan, ClientPaymentMethods, AddStripePaymentCard } from "../components/PaymentOptions";
import { CategoryPriceList } from "../components/CategoryPriceList";
import { UserTransactions } from "../components/UserTransactions";
import Onboard from "../components/Onboard";
import { LabeledBox, PhoneNumberInput, FeedBack } from "../components/CustomComponents";
import { FreezeAccount, SetAutoReplenishThreshold } from "../components/AccountManagement";
import { PolicyPage, ToS } from "./Policy";

import { ABBR_TO_STATE } from "../services/constants";


export default function AccountDashboard() {
  const { legal_categories, communication_methods, target_locations, user_subscription, roles } = useSelector((state) => state.user);
  const { stripe_customer_id } = user_subscription;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showOnboard, setShowOnboard] = useState(false);
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (_.isEmpty(legal_categories) && _.isEmpty(communication_methods) && _.isEmpty(target_locations)) {
      setShowOnboard(true);
    } else {
      setShowOnboard(false);
    }
  }, [legal_categories, communication_methods, target_locations]);

  return (
    <Box sx={{
      minHeight: "100vh",
      // height: "100%",
      // width: "100vw",
      display: "flex",
      flexDirection: bp ? "column" :"row"
    }}>
      {bp ? <MobileHeader selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} /> : <MenuList selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />}
      <Box display="flex" flexDirection="column" mt={8} ml={2} mb={4}>
        {showOnboard ? 
          <Onboard /> :
          stripe_customer_id ? 
            <Container maxWidth="xl">
              {selectedIndex === 0 && <Account />}
              {selectedIndex === 1 && <LeadManagement />}
              {selectedIndex === 2 && <BillingPage />}
              {selectedIndex === 3 && <AccountSettings />}
              {selectedIndex === 5 && <PolicyPage />}
              {selectedIndex === 6 && <ToS />}
            </Container> : 
          <Box>
              <CreateCustomer />
          </Box>
        }
      </Box>
    </Box>
  );
}

const TRIAL_INTRO = "The trial phase includes the delivery of three fully qualified leads, each accompanied by a direct handoff to the law firm. Firms will not be billed until the successful handoff of the third qualified lead, ensuring a risk-free introduction to our service. This trial phase allows firms to experience the quality and effectiveness of our lead generation process before any financial commitment."

const CreateCustomer = () => {
  return (
    <Stack direction="column" spacing={4} maxWidth="600px">
      <Typography variant="h4">Your leads are on the way!</Typography>
      <Typography fontSize="1.25rem">{TRIAL_INTRO}</Typography>
      <LabeledBox label="Add your payment method">
        <Box maxWidth="400px" mt={5} p={2}>
          <StripePayment />
        </Box>
      </LabeledBox>
      <CategoryPriceList />
    </Stack>
  );
}


const Account = () => {
  const { plans } = useSelector((state) => state.payment);
  const { business_name, user_subscription } = useSelector((state) => state.user);
  const { credit_balance, special_condition, subscription_plan_id } = user_subscription;
  const [balance, setBalance] = useState(credit_balance);
  const [offers, setOffers] = useState(null);
  const [isTrial, setIsTrial] = useState(true);
  const [leadCount, setLeadCount] = useState(null);

  useEffect(() => {
    if (plans.length > 0) {
      const plan = plans.find(p => p.plan_title == 'trial');
      if (subscription_plan_id == plan.id) {
        setBalance(<>{`${plan.plan_title} Plan`}<EmojiEmotionsIcon fontSize="medium" sx={{ ml:1 }}/></>);
        setIsTrial(true);
      } else {
        setIsTrial(false);
        setBalance(showDollars(credit_balance));
      }
    }
  }, [plans, subscription_plan_id, credit_balance, user_subscription]);

  useEffect(() => {
    if (special_condition) {
      let tmpCount = 0;
      const tmp = [];
      const keys = Object.keys(special_condition);
      keys.forEach(key => {
        if (special_condition[key]) {
          for (const l_type in special_condition[key]) {
            if (special_condition[key][l_type] != null) {
              tmpCount += special_condition[key][l_type]; 
              const offer = {
                "label": key.replace("_", " "),
                "type": l_type,
                "count": special_condition[key][l_type]
              }
              tmp.push(offer)
            }
          }
        }
      })
      setOffers(tmp);
      setLeadCount(tmpCount);
    }
  }, [special_condition]);

  return (
    <Stack direction= "column" spacing={3}>
      <Typography fontSize="3rem" fontWeight={600}>Welcome, {business_name}</Typography>
      <Container maxWidth='xl'>
        <Grid2 container spacing={1}>
          <>
            <Grid2 size={{xs: 12, md: 6}}>
              <Typography fontSize="1.75rem"  textTransform="capitalize">Balance: {balance}</Typography>
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}>
              
            </Grid2>
            {!isTrial && <Grid2 size={{xs: 12, md: 12}}>
              <Stack>
                <FreezeAccount />
                <SetAutoReplenishThreshold />
              </Stack>
            </Grid2>}
            
          </>
          <Grid2 size={{xs: 12}} mt={1.5}>
            <Stack direction="column" spacing={1}>
              {offers &&
              <>
                <Typography variant="h5" fontWeight={600} >{isTrial ? "Trial Leads!" : "Bonus Leads!"}</Typography>
                <Divider flexItem sx={{ border: "1px solid" }} />
                {offers.map(offer => {
                  const { type, count } = offer;
                  return (
                    <Stack direction="column" spacing={.5}>
                      <Stack direction="row" spacing={1}>
                        <Typography fontSize="1.2rem" textTransform="capitalize">{`${type}: `}</Typography>
                        <Typography fontSize="1.2rem" textTransform="capitalize" fontWeight="600" color="red">{`${count}`}</Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </>}
              {isTrial && leadCount == 0 && 
                <Box display="flex" flexDirection="column" alignItems="center" alignSelf="center">
                  <Typography variant="h4" color="red" textAlign="center">Now that your trial phase has ended... lets pick you a plan!</Typography>
                  <Box mt={2}>
                    <SelectPlan />
                  </Box>
                </Box>}
            </Stack>
          </Grid2>
          <Grid2 size={{xs: 12}} mt={3}>
            <AccountLeads />
          </Grid2>
        </Grid2>
      </Container>
    </Stack>
  );
}

const AccountLeads = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">Leads</Typography>
      {bp ? 
        <LeadList /> : 
        <LeadDataTable />}
    </Stack>
  );
}

const LeadList = () => {
  const user_leads = useSelector((state) => state.lead.user_leads);
  const legal_categories = useSelector((state) => state.lead.categories);
  const [leads, setLeads] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setLeads(user_leads);
  }, [user_leads])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  

  return (
    <ThemeProvider theme={createTheme(dark)}>
      <Paper>
        <List>
          {leads ? 
            leads.map(lead => {
              const { first_name, last_name, phone, email, category_id, qualification, state_abbr, county, created } = lead;
              const fullName = `${first_name || ''} ${last_name || ''}`.toUpperCase();
              return (
                <>
                  <ListItem
                    onClick={handleClick}
                    secondaryAction={
                      <IconButton edge="end" aria-label="lead info" >
                        <InfoIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={fullName}
                    />
                  </ListItem>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                  >
                    <Stack direction="column" spacing={1} sx={{p: 2, minWidth: "300px"}}>
                      {/* <Stack direction="row" spacing={.5}> */}
                        {/* <Typography variant="subtitle1" >{`Full Name: `}</Typography> */}
                        {/* <Typography variant="subtitle1" fontWeight={600}>{`${lead.first_name || ''} ${lead.last_name || ''}`.toUpperCase()}</Typography> */}
                      {/* </Stack> */}
                      <Typography variant="h6" fontWeight={600}>Lead Details</Typography>
                      <Divider flexItem />
                      <Typography variant="subtitle2" fontWeight={600}>{fullName}</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>{`(${phone.slice(0,3)}) ${phone.slice(3,6)} ${phone.slice(6,10)}`}</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>{email}</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>{legal_categories.find(lc => lc.id == category_id).category} - {qualification}</Typography>
                      {/* <Typography variant="subtitle2" fontWeight={600}>{qualification}</Typography> */}
                      <Typography variant="subtitle2" fontWeight={600}>{state_abbr}, {county}</Typography>
                      {/* <Typography variant="subtitle2" fontWeight={600}>{county}</Typography> */}
                      <Typography variant="subtitle2" fontWeight={600}>{new Date(created).toLocaleDateString("en-US", { hour: 'numeric', minute: 'numeric' })}</Typography>
                    </Stack>
                  </Popover>
                  <Divider flexItem sx={{ border: "1px solid grey", mx: 1 }} />
                </>
              );
            }) :
            <>
              <Box px={2}>
                <Typography variant="h5" fontWeight={600} textAlign="center">Your leads will appear here!</Typography>
              </Box>
            </>
          }
        </List>
      </Paper>
    </ThemeProvider>
  );
}

const LeadDataTable = () => {
  const user_leads = useSelector((state) => state.lead.user_leads);
  const legal_categories = useSelector((state) => state.lead.categories);
  const [leads, setLeads] = useState(user_leads);
  const paginationModel = { page: 0, pageSize: 10 };
  const columns = [
    // { field: "price", headerName: "Cost" },
    { field: "fullName", width: 150, headerName: "Name", valueGetter: (value, row) => `${row.first_name || ''} ${row.last_name || ''}`.toUpperCase(), },
    { field: "category_id", width: 150, headerName: "Category", valueGetter: (value) => legal_categories.find(lc => lc.id == value).category },
    { field: "phone", width: 124, headerName: "Phone", valueGetter: (value) => `(${value.slice(0,3)}) ${value.slice(3,6)} ${value.slice(6,10)}` },
    { field: "email", headerName: "Email" },
    { field: "qualification", headerName: "Qualification" },
    { field: "state_abbr", headerName: "State" },
    { field: "county", headerName: "County" },
    { field: "created", width: 155, headerName: "Assigned At", valueGetter: (value) => new Date(value+"Z").toLocaleDateString("en-US", { hour: 'numeric', minute: 'numeric' }) },
    { field: "lead_uniq_code", headerName: "Lead Id", sortable: false },
  ];

  useEffect(() => {
    setLeads(user_leads);
  }, [user_leads]);
  
  return (
    <ThemeProvider theme={createTheme(dark)}>
      <Paper sx={{ width: '100%' }}>
        <DataGrid
          rows={leads}
          columns={columns}
          initialState={{ pagination: { paginationModel }, sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          }  }}
          pageSizeOptions={[10, 25, 50]}
          autosizeOnMount={true}
          sx={{ border: 0 }}
        />
      </Paper>
    </ThemeProvider>
  );
}





const StripeGrid = () => {
//   <script async
//   src="https://js.stripe.com/v3/buy-button.js">
// </script>

// <stripe-buy-button
//   buy-button-id="buy_btn_1QAyxeRpg0pKTArZ45AYnuzV"
//   publishable-key="pk_test_51Q06p3Rpg0pKTArZaEIMDScNgOQHKMUBpIWzRv2JGysIKUD1JUwvAlKRK956kAF9hfFzLFfoog9QCB3JIjixKA5400bdCmjrVV"
// >
// </stripe-buy-button>
}

const BillingPage = () => {
  const { plans } = useSelector((state) => state.payment);
  const { user_subscription } = useSelector((state) => state.user);
  const { subscription_plan_id } = user_subscription;
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({method_verified: true, make_default: checked});
  const [isTrial, setIsTrial] = useState(true);

  useEffect(() => {
    if (plans.length > 0) {
      const plan = plans.find(p => p.plan_title == 'trial');
      if (subscription_plan_id == plan.id) {
        setIsTrial(true);
      } else {
        setIsTrial(false);
      }
    }
  }, [plans, subscription_plan_id]);
  

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setData({...data, make_default: event.target.checked})
  };

  
  return (
    <Stack direction="column" spacing={5}>
      <Typography fontSize="3rem" fontWeight={600}>Account Billing</Typography>
      {!isTrial && <Box>
        <SelectPlan />
      </Box>}
      <Box sx={{ maxWidth: "400px" }}>
        <LabeledBox label="Add Payment Method">
          <Box px={2} py={2} mt={2}>
            <Stack spacing={1}>
              <AddStripePaymentCard data={data} reduxAction={addPaymentMethod}/>
              <Stack direction="row" alignItems="center">
                <Typography>Make Default?</Typography>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </Stack>
          </Box>
        </LabeledBox>
      </Box>
      <Box>
        <ClientPaymentMethods />
      </Box>
      <Box>
        <Typography fontSize="2rem" fontWeight={600}>Transactions</Typography>
        <UserTransactions />
      </Box>
    </Stack>
  );
}

const LeadManagement = () => {
  return (
    <>
    <Stack direction="column" spacing={5}>
      <Typography fontSize="3rem" fontWeight={600}>Lead Management</Typography>
      <CategoriesSelection />
      <GeoregionSelection />
      <SelectCommunicationMethods />
    </Stack>
    <Box maxWidth={400}>
      <Typography fontSize="2rem" fontWeight={600}>Category Price List</Typography>
      <CategoryPriceList />
    </Box>
    </>
  )
}

const AccountSettings = () => {
  const dispatch = useDispatch();
  const { first_name, last_name, business_name, email, phone } = useSelector((state) => state.user);
  const [f_name, setFname] = useState(first_name);
  const [l_name, setLname] = useState(last_name);
  const [b_name, setBusinessName] = useState(business_name);
  const [p_number, setPhoneNumber] = useState(`(${phone.slice(0,3)}) ${phone.slice(3,6)} ${phone.slice(6,10)}`);
  const [u_email, setUserEmail] = useState(email);
  const [currentPw, setCurrentPw] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    if (pass1 && pass2) {
      if ( checkPassword(pass1) && checkPassword(pass2) && pass1 == pass2 && !isNullOrEmpty(currentPw) ) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [currentPw, pass1, pass2]);

  const resetPassword = async () => {
    try {
      const payload = {
        current_password: currentPw,
        new_password: pass2
      }
      await dispatch(resetPw(payload)).unwrap();
      await dispatch(logOut()).unwrap();
      // setFeedback({ passed: true, comp: <FeedBack passed={true} message="Categories updated!" color="black"/> });
    } catch (exception) {
      // setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }

  return (
    <Box display="flex" flexDirection="column" >
      <Stack direction= "column" spacing={3}>
        <Typography fontSize="2rem">Account Details</Typography>
        <Stack direction= "column" spacing={1}>
          <Typography fontSize="1.25rem">Contact Name</Typography>
          <Stack direction="row" spacing={1}>
            <TextField fullWidth variant="filled" disabled value={f_name} onChange={(e) => setFname(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", }} />
            <TextField fullWidth variant="filled" disabled value={l_name} onChange={(e) => setLname(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", }} />
          </Stack>
        </Stack>
        <Stack direction= "column" spacing={1}>
          <Typography fontSize="1.25rem">Business Name</Typography>
          <TextField fullWidth variant="filled" disabled value={b_name} onChange={(e) => setBusinessName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", }} />
        </Stack>
        <Stack direction= "column" spacing={1}>
          <Typography fontSize="1.25rem">E-mail</Typography>
          {/* <Typography fontSize="1.25rem" >{user_email}</Typography> */}
          <TextField fullWidth variant="filled" disabled value={u_email} onChange={(e) => setUserEmail(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", }} />
        </Stack>
        <Stack direction= "column" spacing={1}>
          <Typography fontSize="1.25rem">Phone Number</Typography>
          {/* <PhoneNumberInput fullWidth variant="filled" disabled initialNum={p_number} setPhoneNumber={setPhoneNumber} /> */}
          <TextField fullWidth variant="filled" disabled value={p_number} onChange={(e) => setPhoneNumber(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", }} />
        </Stack>
        <Stack direction= "column" spacing={1}>
          <Typography fontSize="1.25rem">Change Password</Typography>
          <Stack direction="column" spacing={1}>
            <PasswordField name="password" showRequirements={false} label={<Typography fontSize="1rem" fontWeight="600" px={1}>Current Password*</Typography>} variant="standard" onChange={(e) => setCurrentPw(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
            <PasswordField name="password" showRequirements={false} label={<Typography fontSize="1rem" fontWeight="600" px={1}>New Password*</Typography>} variant="standard" onChange={(e) => setPass1(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
            <PasswordField name="password" showRequirements={false} label={<Typography fontSize="1rem" fontWeight="600" px={1}>New Password*</Typography>} variant="standard" onChange={(e) => setPass2(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
          </Stack>
        </Stack>
        <Button variant="contained" size="large" disabled={btnDisabled} onClick={resetPassword} sx={{ bgcolor: "blue", borderRadius: 5, width: "250px", height: "3rem", mt: 2 }}>Update Password</Button>
      </Stack>
    </Box>
  );
}


const CategoriesSelection = () => {
  const dispatch = useDispatch();
  const legal_categories = useSelector((state) => state.lead.categories);
  const current_categories = useSelector((state) => state.user.legal_categories);
  const [selectedCategories, setSelectedCategories] = useState(current_categories);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });


  useEffect(() => {
    setSelectedCategories(current_categories);
  }, [current_categories]);
  
  useEffect(() => {
    if (_.isEqual(_.sortBy(selectedCategories, num =>  num), _.sortBy(current_categories, num =>  num))) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }

  }, [selectedCategories]);

  const checkAction = (id) => {
    let selected = [...selectedCategories];
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }
    setSelectedCategories(selected);
  }

  const submitCategories = async () => {
    try {
      const payload = {
        categories: selectedCategories,
      }
      dispatch(updateCategories(payload));
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Categories updated!" color="black"/> });
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }

  return (
    <Stack spacing={1}>
      <LabeledBox label="Your Practice Areas">
        <FormGroup sx={{ p: 2 }}>
          <Grid2 container spacing={1}>
            {legal_categories.map((v, idx) => {
              const { id, category, is_disabled } = v;
              if (is_disabled) {
                return;
              }
              const is_checked = selectedCategories.includes(id);
              return (
                <Grid2 size={{xs: 12, md: 6}} key={idx}>
                  <FormControlLabel disabled={id == 1} label={category} control={<Checkbox checked={is_checked} onClick={() => checkAction(id) }/>} />
                </Grid2>
              );
            })}
          </Grid2>
        </FormGroup>
      </LabeledBox>
      <Stack spacing={1} direction="column" alignSelf="center" alignItems="center">
        <Button variant="contained" size="large" disabled={btnDisabled} onClick={submitCategories} sx={{ bgcolor: "blue", borderRadius: 5, width: "250px", height: "3rem", mt: 2, alignSelf: "center" }}>Update Categories</Button>
        {feedBack.passed !== null && feedBack.comp}
      </Stack>
    </Stack>
  );
}

const GeoregionSelection = () => {
  const dispatch = useDispatch();
  const current_regions = useSelector((state) => state.user.target_locations);
  const geo_regions = useSelector((state) => state.lead.regions);
  const state_list = Object.keys(geo_regions);
  const [regions, setRegions] = useState(current_regions)
  const [region_list, set_region_list] = useState([])
  const [states, set_states] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if (Array.isArray(regions) && regions.length > 0) {
      const initRegionPayload = {};
      regions.forEach(item => {
        initRegionPayload[item['state']] = item['regions']
      })
      setBtnDisabled(_.isEqual(current_regions, initRegionPayload))
    } else {
      setBtnDisabled(true);
    }

  }, [regions]);

  useState(() => {
    if (current_regions) {
      let tmp_regions = []
      const selectedStates = Object.keys(current_regions);
      selectedStates.forEach(st => {
        current_regions[st].forEach(r_id => {
          tmp_regions.push(`${st}-${r_id}`)
        })
      });
      set_states(selectedStates);
    }
  }, [current_regions]);
  
  useEffect(() => {
    let tmp_region_list = [];
    states.forEach(st => {
      tmp_region_list.push({ state: st, regions: geo_regions[st] })
      
    });
    set_region_list(tmp_region_list);
  }, [states]);

  const submitGeolocation = async () => {
    try {
      const payload = {
        geolocation: regions,
      }
      dispatch(updateGeoregions(payload));
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Regions updated!" color="black"/> });
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }
  
  return (
    <Stack spacing={1}>
      <LabeledBox label="Where Do You Want Leads From?">
        <Grid2 container spacing={2} mt={3}>
          <Grid2 size={{xs: 12}}>
            <StateSelection list={state_list} action={set_states} />
          </Grid2>
          <Grid2 size={{xs: 12}}>
            <RegionSelection list={region_list} action={setRegions}/>
          </Grid2>
        </Grid2>
      </LabeledBox>
      <Stack spacing={1} direction="column" alignSelf="center" alignItems="center">
        <Button variant="contained" disabled={btnDisabled} onClick={submitGeolocation} size="large" sx={{ bgcolor: "blue", borderRadius: 5, width: "250px", height: "3rem", mt: 2, alignSelf: "center" }}>
          Update Region
        </Button>
        {feedBack.passed !== null && feedBack.comp}
      </Stack>
    </Stack>
  );
}

const StateSelection = ({list, action }) => {
  const [selected_states, set_selected_states] = useState([]);
  useEffect(() => {
    action(selected_states);
  }, [selected_states])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    set_selected_states(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel sx={{ mb: 5, fontSize: "1.25rem", bgcolor: "white" }}>Select State</InputLabel>
      <Select
        labelId="select state"
        multiple
        value={selected_states}
        onChange={handleChange}
        input={<OutlinedInput  label="Chip" />}
        renderValue={(selected) => {
          return (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>);
        }}
      >
        {list.map((name) => (
          <MenuItem
            key={name}
            value={name}
          >
            {ABBR_TO_STATE[name]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const RegionSelection = ({list, action}) => {
  const [region_list, set_region_list] = useState([]);
  const [display_list, set_display_list] = useState([]);

  const checkAction = (id) => {
    let selected = [...region_list];
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }
    set_region_list(selected);
    let payload = []
    for (const item in selected) {
      let [st, reg] = selected[item].split("-");
      const st_idx = payload.findIndex(i => i.state == st);
      if (reg) {
        reg = Number(reg);
        if (st_idx !== -1) {
          payload[st_idx]['regions'].push(reg);
        } else {
          const temp = {
            'state': st,
            'regions': [reg]
          }
          payload.push(temp);
        }
        action(payload);
      }
    }
    if (selected.length == 0) {
      action([]);
    } 
      
  }

  useEffect(() => {
    let tempt_list = [];
    list.forEach(item => {
      const { state, regions} = item;
      tempt_list.push({state: state})
      for ( const [region, counties] of Object.entries(regions) ) {
        tempt_list.push({region: region, st: state});
        counties.forEach((county) => {
          tempt_list.push({county: county});
        })
      }
    })
    set_display_list(tempt_list);
  }, [list]);

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel sx={{ mb: 5, fontSize: "1.25rem", bgcolor: "white" }}>Select Region</InputLabel>
      <Select
        labelId="select region"
        multiple
        value={region_list}
        input={<OutlinedInput  label="Select Region" />}
        renderValue={(selected, idx) => {
          return (<Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              let [st, reg] = value.split("-");
              return (<Chip key={value} label={value} />);
        })}
          </Box>);
        }}
      >
        <List>
          {display_list.map((item, idx) => {
            if('state' in item) {
              return (
                <>
                  {/* <Divider flexItem sx={{border: 1 }}/> */}
                  <ListItemButton key={idx} disabled color="black" sx={{ "&:disabled": { color: "black" } }}>
                    <ListSubheader><Typography variant="h6" color="black">{ `${ABBR_TO_STATE[item['state']]} State` }</Typography></ListSubheader>
                  </ListItemButton>
                  <Divider flexItem sx={{border: 1 }}/>
                </>
              );
            } else if ('region' in item) {
              const value = `${item['st']}-${item['region']}`;
              const is_checked = region_list.includes(value);
              return (
                <Box key={idx}>
                  <ListItemButton onClick={() => checkAction(value)}>
                    <Checkbox checked={is_checked} />
                    <ListItemText primary={`Region ${item['region']}`} />
                  </ListItemButton>
                  <Divider flexItem/>
                </Box>
              );
            } else if ('county' in item) {
              return (
                <ListItemButton key={idx} disabled>
                  <Typography variant="subtitle1">{item['county']}</Typography>
                </ListItemButton>
              );
            }
          })}
        </List>
      </Select>
    </FormControl>
  );
}

const SelectCommunicationMethods = () => {
  const dispatch = useDispatch();
  const { email, phone } = useSelector((state) => state.user.communication_methods);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [leadEmail, setLeadEmail] = useState(email);
  const [communicationMethods, setCommunicationMethods] = useState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if ((phoneNumber == phone && leadEmail == email) || (!isEmail(leadEmail) || !verifyPhoneNumber(phoneNumber))) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }

  }, [phoneNumber, leadEmail, email, phone]);
  
  useEffect(() => {
    const payload = {
      'email': leadEmail,
      'phone': phoneNumber
    }
    setCommunicationMethods(payload);
  }, [phoneNumber, leadEmail]);

  const submitCommunication = async () => {
    try {
      dispatch(updateCommunication(communicationMethods));
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Communication methods updated!" color="black"/> });
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }

  return(
    <Stack spacing={1}>
      <LabeledBox label="Where Should We Route Your leads?">
        <Stack spacing={3} mt={2} py={1}>
          <TextField name="email" value={leadEmail} label={<Typography fontSize="1rem" fontWeight="600" px={1}>Email*</Typography>} variant="standard" onChange={(e) => setLeadEmail(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
          <PhoneNumberInput initialNum={phoneNumber} setPhoneNumber={setPhoneNumber} />
        </Stack>
      </LabeledBox>
      <Stack spacing={1} direction="column" alignSelf="center" alignItems="center">
        <Button variant="contained" disabled={btnDisabled} onClick={submitCommunication} size="large" sx={{ bgcolor: "blue", borderRadius: 5, width: "250px", height: "3rem", mt: 2, alignSelf: "center" }}>
          Update Methods
        </Button>
        {feedBack.passed !== null && feedBack.comp}
      </Stack>
    </Stack>
  );
}

const MENU_HOVER_COLOR = "#4fc3f7";
export const MenuListItem = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  cursor: "pointer",
}));


const MobileHeader = ({ selectedIndex, setSelectedIndex }) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>      
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Flyers
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <MenuList selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
      </Drawer>
    </>
  );
  
}

const MenuList = ({ selectedIndex, setSelectedIndex }) => {
  const dispatch = useDispatch();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#1769aa', height:"inherit" }}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, height: '100%' }}>
        <List component="nav" aria-label="menu list" sx={{ flex: 1, flexGrow: 12 }}>
          <MenuListItem
            selected={selectedIndex === 0}
            onClick={(e) => handleListItemClick(e,0)}
            sx={{
              ...(selectedIndex === 0) && {
                bgcolor: MENU_HOVER_COLOR
              },
              "&:hover" : {
                bgcolor: MENU_HOVER_COLOR
              },
            }}
          >
            <ListItemIcon>
              <HomeIcon fontSize="large" sx={{ color: "white" }}/>
            </ListItemIcon>
            <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Dashboard</Typography>
          </MenuListItem>
          {/* <Divider sx={{ my: 2}} /> */}
          <MenuListItem
            selected={selectedIndex === 1}
            onClick={(e) => handleListItemClick(e,1)}
            sx={{
              ...(selectedIndex === 1) && {
                bgcolor: MENU_HOVER_COLOR
              },
              "&:hover" : {
                bgcolor: MENU_HOVER_COLOR
              },
            }}
          >
            <ListItemIcon>
              <LeaderboardIcon fontSize="large" sx={{ color: "white" }}/>
            </ListItemIcon>
            <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Lead Management</Typography>
          </MenuListItem>
          <MenuListItem
            selected={selectedIndex === 2}
            onClick={(e) => handleListItemClick(e,2)}
            sx={{
              ...(selectedIndex === 2) && {
                bgcolor: MENU_HOVER_COLOR
              },
              "&:hover" : {
                bgcolor: MENU_HOVER_COLOR
              },
            }}
          >
            <ListItemIcon>
              <AddCardIcon fontSize="large" sx={{ color: "white" }}/>
            </ListItemIcon>
            <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Billing</Typography>
          </MenuListItem>
          <MenuListItem
            selected={selectedIndex === 3}
            onClick={(e) => handleListItemClick(e,3)}
            sx={{
              ...(selectedIndex === 3) && {
                bgcolor: MENU_HOVER_COLOR
              },
              "&:hover" : {
                bgcolor: MENU_HOVER_COLOR
              },
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon fontSize="large" sx={{ color: "white" }}/>
            </ListItemIcon>
            <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Settings</Typography>
          </MenuListItem>
          <Divider sx={{ mb: 4}} />
          <MenuListItem
            selected={selectedIndex === 4}
            onClick={() => dispatch(logOut())}
            sx={{
              "&:hover" : {
                bgcolor: MENU_HOVER_COLOR
              },
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="large" sx={{ color: "white" }}/>
            </ListItemIcon>
            <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Logout</Typography>
          </MenuListItem>
        </List>
        <Stack direction="row" spacing={1} alignSelf="center" sx={{ mb: 2, marginTop: "auto" }}>
          <Typography variant="body2" onClick={(e) => handleListItemClick(e,5)} sx={{ '&:hover': {cursor: "pointer"} }}>Privacy Policy</Typography>
          <Typography variant="body2" onClick={(e) => handleListItemClick(e,6)} sx={{ '&:hover': {cursor: "pointer"} }}>Terms of Service</Typography>
        </Stack>
      </Box>
    </Box>
  );
}


const light = {
  palette: {
    mode: "light",
  },
};

const dark = {
  palette: {
    mode: "dark",
  },
};

