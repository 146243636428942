import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useMediaQuery, useTheme, Paper } from "@mui/material";
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';


import { showDollars } from "../services/utilities";


const dark = {
  palette: {
    mode: "dark",
  },
};


export const CategoryPriceList = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  const { categories, price_list } = useSelector((state) => state.lead);
  const [priceList, setPriceList] = useState(null);
  const columns = [
    { field: "category", width: 180, headerName: "Category" },
    { field: "qualification", headerName: "Qualification" },
    { field: "price", headerName: "Cost", valueGetter: (value) => `${showDollars(value)}` },
  ];

  useEffect(() => {
    if (categories && price_list) {
      const p_list = [];
      price_list.forEach((p, idx) => {
        p_list.push({
          id: idx,
          category: categories.find(c => c.id == p.category_id).category,
          qualification: p.qualification,
          price: p.price,
        })
      });
      setPriceList(p_list);
      
    }
  }, [price_list, categories]);
  
  return (
    <ThemeProvider theme={createTheme(dark)}>
      <Paper sx={{ width: '100%' }}>
        <DataGrid
          rows={priceList}
          columns={columns}
          pagination={false}
          hideFooter={true}
          initialState={{ sorting: {
            sortModel: [{ field: 'category', sort: 'asc' }],
          }  }}
          sx={{ border: 0 }}
        />
      </Paper>
    </ThemeProvider>
  );
}