import React, { useEffect } from "react";

// components
import Router from "./Router";
import VerifiedRouter from "./VerifiedRouter";

// redux
import { getCategories, getRegions, getClientLeads, getCategoryPrices } from "./redux/slices/lead-slice";
import { getClientSubscription } from "./redux/slices/user-slice";
import { getSubPlans, getUserTransactions } from "./redux/slices/payment-slice";
import { useSelector, useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const { is_verified, token} = useSelector((state) => state.user);

  const initializeData = async () => {
    await dispatch(getCategories()).unwrap();
    await dispatch(getRegions()).unwrap();
    await dispatch(getClientSubscription()).unwrap();
    await dispatch(getSubPlans()).unwrap();
    await dispatch(getClientLeads()).unwrap();
    await dispatch(getCategoryPrices()).unwrap();
    await dispatch(getUserTransactions()).unwrap();
  }

  useEffect(()=> {
    if(is_verified && token ) {
      initializeData();
    }
  }, [is_verified, token]);
  return (
    <>
      {is_verified ? <VerifiedRouter /> : <Router />}
    </>
  );
}

export default App;
