import React, { useEffect, useState } from "react";
import _ from "underscore";
import {
  Stack, Typography, Button, Box, Container, FormGroup, Grid2, FormControlLabel, FormControl,
  Checkbox, Stepper, Step, StepLabel, TextField, List, ListItemText, ListSubheader, 
  OutlinedInput, InputLabel, MenuItem, Select, Chip, ListItemButton, Divider } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { onboardUser, rehydrateUser } from "../redux/slices/user-slice";

import { PhoneNumberInput, LabeledBox } from "./CustomComponents";

import DeleteIcon from '@mui/icons-material/Delete';

import { ABBR_TO_STATE } from "../services/constants";
import { isEmail } from "../services/utilities";



export default function Onboard() {
  return (
    <Stack direction= "column" spacing={12}>
      <OnboardClientStepper />
    </Stack>
  );
}

const steps = ['Areas of Practice', 'Lead Notification Method', 'Location of Practice'];
const TRIAL_INTRO = "The trial phase includes the delivery of three fully qualified leads, each accompanied by a direct handoff to the law firm. Firms will not be billed until the successful handoff of the third qualified lead, ensuring a risk-free introduction to our service. This trial phase allows firms to experience the quality and effectiveness of our lead generation process before any financial commitment."

const OnboardClientStepper = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [category_payload, set_category_payload] = useState([]);
  const [comm_payload, set_comm_payload] = useState({});
  const [region_payload, set_region_payload] = useState([]);
  const [next_disabled, set_next_disabled] = useState(true);

  const payload = {
    categories : category_payload,
    lead_communication: comm_payload,
    geolocation: region_payload
  };

  useEffect(() => {
    if (activeStep === 0) {
      if (_.isEmpty(category_payload) || category_payload.length < 1) {
        set_next_disabled(true);
      } else {
        set_next_disabled(false);
      }
    } else if (activeStep === 1) {
      if (_.isEmpty(comm_payload)) {
        set_next_disabled(true);
      } else {
        const curr_email = comm_payload['email'];
        const curr_phone = comm_payload['phone'];
        if ((_.isEmpty(curr_email)) || (_.isEmpty(curr_phone)) || !isEmail(curr_email) || curr_phone.length < 10) {
          set_next_disabled(true);
        } else {
          set_next_disabled(false);
        }
      }
    } else if (activeStep === 2) { 
      if (_.isEmpty(region_payload)) {
        set_next_disabled(true);
      } else {
        set_next_disabled(false);
      }
    }
  }, [activeStep, category_payload, comm_payload, region_payload]);

  const handleNext = () => {
    if (activeStep === 2) {
      submitPayload();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const submitPayload = async () => {
    try {
      await dispatch(onboardUser(payload)).unwrap();
      dispatch(rehydrateUser());
    } catch (exception) {
      
    }
  }

  const handleBack = () => {
    set_region_payload([])
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <Stack maxWidth={600} spacing={2} my={4}>
            <Typography variant="h5">Welcome Aboard!</Typography>
            <Typography fontSize="1.25rem">{TRIAL_INTRO}</Typography>
          </Stack>
          <Box mt={3} width="parent">
            {activeStep === 0 && <SelectCategories categories={category_payload} setCategories={set_category_payload} />}
            {activeStep === 1 && <SelectCommunicationMethods communicationMethods={comm_payload} setCommunicationMethods={set_comm_payload}/>}
            {activeStep === 2 && <SelectRegions region={region_payload} setRegions={set_region_payload} />}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
          <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} variant="contained" disabled={next_disabled}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
    </Container>
  );
}

// const DISABLED_ON_ONBOARD = ['Criminal - C. Court']
const DISABLED_ON_ONBOARD = []
const SelectCategories = ({ categories, setCategories }) => {
  const legal_categories = useSelector((state) => state.lead.categories);
  const [selectedCategories, setSelectedCategories] = useState( categories.length > 0 ? categories : []);

  const checkAction = (id) => {
    let selected = [...selectedCategories];
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }
    setSelectedCategories(selected);
    setCategories(selected);
  }
  return(
    <LabeledBox label="Select Practice Areas">
      <Container maxWidth="lg" disableGutters sx={{ mt: 1 }}>
        <FormGroup sx={{ p: 2 }}>
          <Grid2 container spacing={1}>
            {legal_categories.map((v, idx) => {
              const { id, category, is_disabled } = v;
              if (is_disabled) {
                return;
              }
              const is_checked = selectedCategories.includes(id);
              return (
                <Grid2 size={{xs: 12, md: 6}} key={category}>
                  <FormControlLabel disabled={DISABLED_ON_ONBOARD.includes(category)} label={category} control={<Checkbox checked={is_checked} onClick={() => checkAction(id) }/>} />
                </Grid2>
              );
            })}
          </Grid2>
        </FormGroup>
      </Container>
    </LabeledBox>
  );
}

const SelectCommunicationMethods = ({ communicationMethods, setCommunicationMethods }) => {
  const { email, phone } = useSelector((state) => state.user);
  const [phoneNumber, setPhoneNumber] = useState( 'phone' in communicationMethods ? communicationMethods['phone'] : phone );
  const [leadEmail, setLeadEmail] = useState('email' in communicationMethods ? communicationMethods['email'] : email);
  
  useEffect(() => {

    const payload = {
      'email': leadEmail,
      'phone': phoneNumber
    }
    setCommunicationMethods(payload);
  }, [phoneNumber, leadEmail])

  return(
    <LabeledBox label="Where Should We Route Your leads?">
      <Stack spacing={3} mt={2}>
        <TextField name="email" value={leadEmail} label={<Typography fontSize="1rem" fontWeight="600" px={1}>Email*</Typography>} variant="standard" onChange={(e) => setLeadEmail(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
        <PhoneNumberInput initialNum={phoneNumber} setPhoneNumber={setPhoneNumber} />
      </Stack>
    </LabeledBox>
  );
}

const SelectRegions = ({ setRegions }) => {
  const geo_regions = useSelector((state) => state.lead.regions);
  const state_list = Object.keys(geo_regions);
  const [region_list, set_region_list] = useState([])
  const [states, set_states] = useState([]);
  
  useEffect(() => {
    let tmp_region_list = [];
    states.forEach(st => {
      tmp_region_list.push({ state: st, regions: geo_regions[st] })
    });
    set_region_list(tmp_region_list);

  }, [states]);
  
  return (
    <LabeledBox label="Where Do You Want Leads From?">
      <Grid2 container spacing={2} mt={3}>
        <Grid2 size={{xs: 12}}>
          <StateSelection list={state_list} action={set_states} />
        </Grid2>
        <Grid2 size={{xs: 12}}>
          <RegionSelection list={region_list} action={setRegions} />
        </Grid2>
      </Grid2>
    </LabeledBox>
  );
}

const StateSelection = ({list, action}) => {
  const [selected_states, set_selected_states] = useState([]);
  useEffect(() => {
    action(selected_states);
  }, [selected_states])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    set_selected_states(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel sx={{ mb: 5, fontSize: "1.25rem", bgcolor: "white" }}>Select State</InputLabel>
      <Select
        labelId="select state"
        multiple
        value={selected_states}
        onChange={handleChange}
        input={<OutlinedInput  label="Chip" />}
        renderValue={(selected) => {
          
          return (<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>);
        }}
      >
        {list.map((name) => (
          <MenuItem
            key={name}
            value={name}
          >
            {ABBR_TO_STATE[name]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const RegionSelection = ({list, action}) => {
  const [region_list, set_region_list] = useState([]);
  const [display_list, set_display_list] = useState([]);

  const checkAction = (id) => {
    let selected = [...region_list];
    if (selected.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }
    set_region_list(selected);
    let payload = []
    for (const item in selected) {
      const [st, reg] = selected[item].split("-");
      const st_idx = payload.findIndex(i => i.state == st);
      if (st_idx !== -1) {
        payload[st_idx]['regions'].push(reg);
      } else {
        const temp = {
          'state': st,
          'regions': [reg]
        }
        payload.push(temp);
      }
      action(payload);
    }
  }

  useEffect(() => {
    let tempt_list = [];
    list.forEach(item => {
      const { state, regions} = item;
      tempt_list.push({state: state})
      for ( const [region, counties] of Object.entries(regions) ) {
        tempt_list.push({region: region, st: state});
        counties.forEach((county) => {
          tempt_list.push({county: county});
        })
      }
    })
    set_display_list(tempt_list);
  }, [list]);

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel sx={{ mb: 5, fontSize: "1.25rem", bgcolor: "white" }}>Select Region</InputLabel>
      <Select
        labelId="select region"
        multiple
        value={region_list}
        input={<OutlinedInput  label="Select Region" />}
        renderValue={(selected, idx) => {
          return (<Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>);
        }}
      >
        <List>
          {display_list.map((item, idx) => {
            if('state' in item) {
              return (
                <>
                  {/* <Divider flexItem sx={{border: 1 }}/> */}
                  <ListItemButton key={idx} disabled color="black" sx={{ "&:disabled": { color: "black" } }}>
                    <ListSubheader><Typography variant="h6" color="black">{ `${ABBR_TO_STATE[item['state']]} State` }</Typography></ListSubheader>
                  </ListItemButton>
                  <Divider flexItem sx={{border: 1 }}/>
                </>
              );
            } else if ('region' in item) {
              const value = `${item['st']}-${item['region']}`;
              const is_checked = region_list.includes(value);
              return (
                <Box key={idx}>
                  <ListItemButton onClick={() => checkAction(value)}>
                    <Checkbox checked={is_checked} />
                    <ListItemText primary={`Region ${item['region']}`} />
                  </ListItemButton>
                  <Divider flexItem/>
                </Box>
              );
            } else if ('county' in item) {
              return (
                <ListItemButton key={idx} disabled>
                  <Typography variant="subtitle1">{item['county']}</Typography>
                </ListItemButton>
              );
            }
          })}
        </List>
      </Select>
    </FormControl>
  );
}
