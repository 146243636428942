// Cache Keys
export const USER_TOKEN = "u_t";
// export const STRIPE_PK = 'pk_test_51Q06p3Rpg0pKTArZaEIMDScNgOQHKMUBpIWzRv2JGysIKUD1JUwvAlKRK956kAF9hfFzLFfoog9QCB3JIjixKA5400bdCmjrVV';
export const STRIPE_PK = 'pk_live_51Q06p3Rpg0pKTArZjAmHFrFwQoeP9k5ZQPXAG6G7waRBK3NrbpHYcBntR3QaFfiZIbSaM6y06yODRH5ltJAKw553008zMZJgWZ';

export const CARD = 'card';
export const ACH = 'ach';
export const ROLES = Object.freeze({
    CLIENT:   "client",
    MODERATOR:  "moderator",
    ADMIN: "admin",
});

export const QUALIFICATION = Object.freeze({
    STANDARD: "standard",
    QUALIFIED: 'qualified',
    COSMIC: "cosmic",
});

// CLIENT = "client"
// MODERATOR = 'moderator'
// ADMIN = "admin"

export const ABBR_TO_STATE = {
    "AL": "Alabama",
    "KY": "Kentucky",
    "OH": "Ohio",
    "AK": "Alaska",
    "LA": "Louisiana",
    "OK": "Oklahoma",
    "AZ": "Arizona",
    "ME": "Maine",
    "OR": "Oregon	",
    "AR": "Arkansas",
    "MD": "Maryland",
    "PA": "Pennsylvania",
    "MA": "Massachusetts",
    "CA": "California",
    "MI": "Michigan",
    "RI": "Rhode Island",
    "CO": "Colorado",
    "MN": "Minnesota",
    "SC": "South Carolina",
    "CT": "Connecticut",
    "MS": "Mississippi",
    "SD": "South Dakota",
    "DE": "Delaware",
    "MO": "Missouri",
    "TN": "Tennessee",
    "DC": "District of Columbia",
    "MT": "Montana",
    "TX": "Texas",
    "FL": "Florida",
    "NE": "Nebraska",
    "GA": "Georgia",
    "NV": "Nevada",
    "UT": "Utah",
    "NH": "New Hampshire",
    "VT": "Vermont",
    "HI": "Hawaii",
    "NJ": "New Jersey",
    "VA": "Virginia",
    "ID": "Idaho",
    "NM": "New Mexico",
    "IL": "Illinois",
    "NY": "New York",
    "WA": "Washington",
    "IN": "Indiana",
    "NC": "North Carolina",
    "WV": "West Virginia",
    "IA": "Iowa",
    "ND": "North Dakota",
    "WI": "Wisconsin",
    "KS": "Kansas",
    "WY": "Wyoming",
};
