import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/user-slice';
import leadSlice from './slices/lead-slice';
import paymentSlice from './slices/payment-slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        lead: leadSlice,
        payment: paymentSlice,
    },
  })
