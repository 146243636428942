import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Container, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';


import { showDollars } from "../services/utilities";


const dark = {
  palette: {
    mode: "dark",
  },
};


export const UserTransactions = () => {
  const paginationModel = { page: 0, pageSize: 10 };
  const { transactions } = useSelector((state) => state.payment);
  const [transactionsList, setTransactionsList] = useState(transactions);
  const columns = [
    { field: "amount", width: 115, headerName: "Cost", valueGetter: (value) => `${showDollars(value)}` },
    { field: "created", width: 170, headerName: "Date", valueGetter: (value) => new Date(value+"Z").toLocaleDateString("en-US", { hour: 'numeric', minute: 'numeric' }) },
  ];

  useEffect(() => {
    setTransactionsList(transactions)
  }, [transactions]);
  
  return (
    <ThemeProvider theme={createTheme(dark)}>
      <Container maxWidth="sm" sx={{mx: 0}} disableGutters>
        <Paper>
          <DataGrid
            rows={transactionsList}
            columns={columns}
            pagination={false}
            hideFooter={true}
            initialState={{ pagination: { paginationModel }, sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            }  }}
            sx={{ border: 0, maxWidth: '100vw' }}
          />
        </Paper>
      </Container>
    </ThemeProvider>
  );
}