import React from 'react';
import { Provider } from "react-redux";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from "./redux/store";
import { rehydrateUser } from './redux/slices/user-slice';

import { setAxiosToken } from './services/client';
import { getLocalStorage } from './services/cache';
import { USER_TOKEN } from './services/constants';

// import reportWebVitals from './reportWebVitals';

const storedToken = getLocalStorage(USER_TOKEN);
if (storedToken) {  
  setAxiosToken(storedToken);
  store.dispatch(rehydrateUser(storedToken));
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
