import axios from "axios";

// export const BASE_URL = 'http://localhost:8000/v1/';
export const BASE_URL = 'https://api.theflyersgroup.com/v1/';

export const authAxios = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export var axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: null,
  },
};

export const setAxiosToken = (token) => {
  axiosConfig.headers.Authorization = token;
};
