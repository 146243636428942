import axios from "axios";
import _ from 'underscore';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL, authAxios, axiosConfig, setAxiosToken } from "../../services/client";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../services/cache";
import { decodeJwt } from "../../services/utilities";
import { USER_TOKEN } from "../../services/constants";


const coreState = {
  client_id: null,
  first_name: null,
  last_name: null,
  business_name: null,
  email: null,
  phone: null,
  roles: [],
  legal_categories: [],
  target_locations: {},
  communication_methods: {},
  user_subscription: {},
  token: null,
  is_verified: false,
};
  
let initialState = {
  ...coreState,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut(state) {
      removeLocalStorage(USER_TOKEN);
      Object.assign(state, coreState);
    },
  },
  extraReducers(builder) {
    loginUserCases(builder);
    registerUserCases(builder);
    resetPwCases(builder);
    rehydrateUserCases(builder);
    onboardUserCases(builder);
    getClientSubscriptionCases(builder);
    updateCategoriesCases(builder);
    updateGeoregionsCases(builder);
    updateCommunicationCases(builder);
    updateAccountFrozenCases(builder);
    updateRechargeThresholdCases(builder);
  },
});
  
export const { logOut } = userSlice.actions;

export default userSlice.reducer;


export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (regPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(BASE_URL + "auth/register", regPayload, config);
      const token = response.data;
      setAxiosToken(token);
      // if (token && rememberUser === true) {
      //   setLocalStorage(USER_TOKEN, token);
      // }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const registerUserCases = (builder) =>
  builder
    .addCase(registerUser.pending, (state) => {
    })
    .addCase(registerUser.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(registerUser.rejected, (state, action) => {
    });

export const loginUser = createAsyncThunk(
  "users/loginUser",
  async ({ userInfo, rememberUser }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(BASE_URL + "auth/login", userInfo, config);
      const token = response.data;
      setAxiosToken(token);
      if (token && rememberUser === true) {
        setLocalStorage(USER_TOKEN, token);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const loginUserCases = (builder) =>
  builder
    .addCase(loginUser.pending, (state) => {
    })
    .addCase(loginUser.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(loginUser.rejected, (state, action) => {
    });

export const resetPw = createAsyncThunk(
  "users/resetPw",
  async (payload, { rejectWithValue }) => {
    try {
      
      const response = await authAxios.post(BASE_URL + "auth/reset_pw", payload, axiosConfig);
      const token = response.data;
      setAxiosToken(token);
      const storedToken = getLocalStorage(USER_TOKEN);
      if (storedToken) {
        removeLocalStorage(USER_TOKEN);
        setLocalStorage(USER_TOKEN, token);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const resetPwCases = (builder) =>
  builder
    .addCase(resetPw.pending, (state) => {
    })
    .addCase(resetPw.fulfilled, (state, action) => {
      const tokenPayload = decodeJwt(action.payload, 1);
      Object.assign(state, tokenPayload);
      state.token = action.payload
      state.is_verified = true;
    })
    .addCase(resetPw.rejected, (state, action) => {
    });

export const rehydrateUser = createAsyncThunk("users/rehydrateUser", async (rejectWithValue) => {
  try {
      const response = await authAxios.get(BASE_URL + "auth/rehydrate", axiosConfig);
      return response.data;
  } catch (err) {
      removeLocalStorage(USER_TOKEN);
      return rejectWithValue(err.response.data);
  }
});

const rehydrateUserCases = (builder) =>
  builder
    .addCase(rehydrateUser.pending, (state) => {
    })
    .addCase(rehydrateUser.fulfilled, (state, action) => {
      const token = action.payload;
      if (token) {
        const tokenPayload = decodeJwt(token, 1);
        Object.assign(state, tokenPayload);
        state.token = token;
        state.is_verified = true;
        if (getLocalStorage(USER_TOKEN)) {
          setLocalStorage(USER_TOKEN, token)
        }
        setAxiosToken(token);
      }
    })
    .addCase(rehydrateUser.rejected, (state, action) => {
      removeLocalStorage(USER_TOKEN);
});


export const onboardUser = createAsyncThunk(
  "users/onboardUser",
  async (payload, { rejectWithValue }) => {
    try {
        const response = await authAxios.post(BASE_URL + "client/onboard", payload, axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const onboardUserCases = (builder) =>
builder
  .addCase(onboardUser.pending, (state) => {
  })
  .addCase(onboardUser.fulfilled, (state, action) => {
      // state.regions = action.payload.regions;
  })
  .addCase(onboardUser.rejected, (state, action) => {
  });

export const getClientSubscription = createAsyncThunk(
  "users/getClientSubscription",
  async (rejectWithValue) => {
    try {
      const response = await authAxios.get(BASE_URL + "client/payment_subscription", axiosConfig);
      return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const getClientSubscriptionCases = (builder) =>
  builder
    .addCase(getClientSubscription.pending, (state) => {
    })
    .addCase(getClientSubscription.fulfilled, (state, action) => {
      if (action.payload) {
        state.user_subscription = {
          ...action.payload,
          special_condition: JSON.parse(action.payload.special_condition)
        }
      }
    })
    .addCase(getClientSubscription.rejected, (state, action) => {
    });
  
export const updateCategories = createAsyncThunk(
  "users/updateCategories",
  async (payload, {rejectWithValue}) => {
    try {
      const response = await authAxios.post(BASE_URL + "client/categories", payload, axiosConfig);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
  );
    
const updateCategoriesCases = (builder) =>
  builder
    .addCase(updateCategories.pending, (state) => {
    })
    .addCase(updateCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.legal_categories = action.payload.categories;
      }
    })
    .addCase(updateCategories.rejected, (state, action) => {
    });

export const updateGeoregions = createAsyncThunk(
  "users/updateGeoregions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(BASE_URL + "client/georegions", payload, axiosConfig);
      return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const updateGeoregionsCases = (builder) =>
  builder
    .addCase(updateGeoregions.pending, (state) => {
    })
    .addCase(updateGeoregions.fulfilled, (state, action) => {
      
      if (action.payload) {
        state.target_locations = action.payload;
      }
    })
    .addCase(updateGeoregions.rejected, (state, action) => {
    });

export const updateCommunication = createAsyncThunk(
  "users/updateCommunication",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(BASE_URL + "client/communication",payload, axiosConfig);
      return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const updateCommunicationCases = (builder) =>
  builder
    .addCase(updateCommunication.pending, (state) => {
    })
    .addCase(updateCommunication.fulfilled, (state, action) => {
      if (action.payload) {
        // state.user_subscription = action.payload;
        state.communication_methods = action.payload
      }
    })
    .addCase(updateCommunication.rejected, (state, action) => {
    });

export const updateAccountFrozen = createAsyncThunk(
  "users/updateAccountFrozen",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(BASE_URL + "client/freeze_account", payload, axiosConfig);
      return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const updateAccountFrozenCases = (builder) =>
  builder
    .addCase(updateAccountFrozen.pending, (state) => {
    })
    .addCase(updateAccountFrozen.fulfilled, (state, action) => {
    })
    .addCase(updateAccountFrozen.rejected, (state, action) => {
    });

export const updateRechargeThreshold = createAsyncThunk(
  "users/updateRechargeThreshold",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(BASE_URL + "client/auto_replenish", payload, axiosConfig);
      return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const updateRechargeThresholdCases = (builder) =>
  builder
    .addCase(updateRechargeThreshold.pending, (state) => {
    })
    .addCase(updateRechargeThreshold.fulfilled, (state, action) => {
    })
    .addCase(updateRechargeThreshold.rejected, (state, action) => {
    });

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async (payload , { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(BASE_URL + "auth/forgot_pw", payload, config);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forgotPasswordReset = createAsyncThunk(
  "users/forgotPasswordReset",
  async (payload , { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(BASE_URL + "auth/forgot_reset", payload, config);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);