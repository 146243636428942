import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, IbanElement, EmbeddedCheckoutProvider, EmbeddedCheckout, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { useMediaQuery, useTheme, Box, Button, Stack, Paper, Typography,
  CardActionArea, Divider, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Card, CardContent, CardActions} from "@mui/material";

import { createCustomer, selectPlan, getPaymentMethods, updateDefaultPaymentId } from "../redux/slices/payment-slice";
import { rehydrateUser, getClientSubscription } from "../redux/slices/user-slice";
import { CARD, ACH, STRIPE_PK } from "../services/constants";

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Test
const stripe_publish_key = STRIPE_PK;


// Initialize Stripe with your public key
const stripePromise = loadStripe(stripe_publish_key);

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: false,
  // layout: 'accordion',
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      // color: "rgb(240, 57, 122)",
      color: "#212121",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

const CardForm = ({ data = null, reduxAction = null }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      const payload = {
        stripe_payment_id: paymentMethod.id,
        payment_type: CARD,
      }
      try {
        await dispatch(createCustomer(payload)).unwrap();
        setSuccess('Payment method saved successfully!');
        dispatch(rehydrateUser());
      } catch(e) {
        setError(e.detail)
        // if (result.error) {
        //   setError(result.error);
        // } 
      }
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} spacing={4}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Button type="submit" variant="contained" disabled={!stripe} sx={{ mt: 2 }}>
        Save Payment Method
      </Button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>{success}</div>}
    </Stack>
  );
}

const CardInput = ({ data = null, reduxAction }) => {
  // const { client_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      const payload = {
        ...data,
        stripe_payment_id: paymentMethod.id,
        payment_type: CARD
      }
      try {
        await dispatch(reduxAction(payload)).unwrap();
        await dispatch(getClientSubscription()).unwrap();
        setSuccess('Payment method saved successfully!');
      } catch(e) {
        setError(e.detail);
      }
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} spacing={4}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      {/* <PaymentElement /> */}
      <Button type="submit" variant="contained" disabled={!stripe} sx={{ mt: 2 }}>
        Save Payment Method
      </Button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>{success}</div>}
    </Stack>
  );
}



export default function StripePayment() {
  return (
    <Elements stripe={stripePromise}>
      <CardForm />
      {/* <PaymentForm /> */}
    </Elements>
    // <EmbeddedPayment />
    
  );
};

export const AddStripePaymentCard = ({ data, reduxAction }) => {
  return (
    <Elements stripe={stripePromise}>
      <CardInput data={data} reduxAction={reduxAction} />
    </Elements>
  );
}

const PLAN_CARDS = [
  {
    title: "gold",
    offer: "1 Free Qualified Lead!",
    cost: "$4,000",
    details: [
      "> Perfect for solo practices",
      "> Newly licenesed lawyers",
      "> Individuals beginning their expansion",
    ]
  },
  {
    title: "platinum",
    offer: "2 Free Qualified Lead!",
    cost: "$8,000",
    details: [
      "> Higher placement in rotation!",
      "> Perfect for partners and small firms",
      "> Taking your practice to the next level",
    ]
  }
]
const PlanCard = ({title, offer, cost, details, planId, user_plan}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const submitPlan = async () => {
    try {
      const payload = {
        plan_id: planId,
      }
      dispatch(selectPlan(payload)).unwrap();
      dispatch(rehydrateUser());
      // setFeedback({ passed: true, comp: <FeedBack passed={true} message="Categories updated!" color="black"/> });
    } catch (exception) {
      // setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper sx={{ p:2, width: "300px", borderRadius: "20px", bgcolor: "lightyellow" }} elevation={18}>
      <CardActionArea onClick={handleClickOpen}>
      <Stack spacing={3}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" fontWeight={900} textTransform="capitalize">{title} Plan</Typography>
          <Typography variant="subtitle2" fontWeight={600}>{offer}</Typography>
        </Box>
        <Box p={.5} display="flex" alignSelf="center" justifyContent="center" border="2px solid black" sx={{borderRadius: "5px"}} >
          <Typography variant="h5" fontWeight={600} >{cost}</Typography>
        </Box>
        <Stack direction="column" spacing={1}>
          <Divider flexItem/>
          {details.map(item => {
            return (<Typography variant="subtitle2" fontWeight={600}>{item}</Typography>  );
          })}
        </Stack>
        <Button variant="contained" size="large" disabled={planId == user_plan}>Select Plan</Button>
        {planId == user_plan && <Typography alignSelf="center" fontWeight={600}>Current Plan</Typography>}
      </Stack>
      </CardActionArea>
      <Dialog
        open={open}
        onClose={handleClose}
      >
      <DialogTitle variant="h4" fontWeight={600} textAlign="center" sx={{ bgcolor: "#1769aa", color: "white" }}>
        {`You've selected the ${title} plan! `}
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#1769aa" }}>
        <DialogContentText color="white">
          Continue getting your leads and have greator control over your account! Selecting confirm will charge using your saved payment method.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ bgcolor: "#1769aa" }}>
        <Button onClick={handleClose} sx={{ color: "white", border: "1px solid white", mr: 3 }} >Go back</Button>
        <Button variant="contained" autoFocus sx={{ boxShadow: "0 0 5px 2px gold"}} onClick={() => {
          handleClose();
          submitPlan();
        }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    </Paper>
  );
}

export const SelectPlan = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  const { plans } = useSelector((state) => state.payment);
  const { subscription_plan_id } = useSelector((state) => state.user.user_subscription);
  return (
    <Stack direction={bp ? "column" : "row"} spacing={2} alignSelf="center">
      {plans.length > 0 && PLAN_CARDS.map(card => {
        const {id, is_disabled} = plans.find(p => p.plan_title == card.title);
        if (is_disabled) {
          return null;
        }
        return (
          <PlanCard {...card} planId={id} user_plan={subscription_plan_id} />
        )
      })}
    </Stack>
  );
}



const EmbeddedPayment = () => {
  const options = {}
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={options}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}

const ACHForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Get the IBAN element data
    const ibanElement = elements.getElement(IbanElement);

    // Create a PaymentMethod for ACH (Bank Account)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'us_bank_account',
      billing_details: {
        name: 'Customer Name',
        email: 'customer@example.com',
      },
      us_bank_account: {
        account_holder_type: 'individual',
        // account_number: '000123456789',  // replace with your test account number
        // routing_number: '110000000',    // replace with your test routing number
      },
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);

      // Send paymentMethod.id to your server
      const response = await fetch('/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payment_method_id: paymentMethod.id }),
      });

      const result = await response.json();

      if (result.error) {
        setError(result.error);
      } else {
        setSuccess('Payment method saved successfully! Customer created.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="iban-element">Bank Account (IBAN)</label>
      <IbanElement
        id="iban-element"
        options={{
          supportedCountries: ['SEPA', 'US'],
          placeholderCountry: 'US',
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
          },
        }}
      />
      <button type="submit" disabled={!stripe}>Submit Payment</button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {success && <div style={{ color: 'green' }}>{success}</div>}
    </form>
  );
};


export const PaymentButton = () => {
  return (
    <stripe-buy-button
      buy-button-id="buy_btn_1QAyxeRpg0pKTArZ45AYnuzV"
      publishable-key={stripe_publish_key}
    >
    </stripe-buy-button>
  );
}




// Initialize Stripe with your public key
// const stripePromise = loadStripe('your-publishable-key');

export const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentType, setPaymentType] = useState('card');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const billingDetails = { name, email };
    let paymentMethod;

    if (paymentType === 'card') {
      // Collect card details
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod: cardPaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });
      if (error) return setError(error.message);
      paymentMethod = cardPaymentMethod;
    } else if (paymentType === 'ach') {
      // Collect bank account details using IBAN
      const ibanElement = elements.getElement(IbanElement);
      const { error, paymentMethod: achPaymentMethod } = await stripe.createPaymentMethod({
        type: 'us_bank_account',
        us_bank_account: { account_holder_type: 'individual' },
        billing_details: billingDetails,
      });
      if (error) return setError(error.message);
      paymentMethod = achPaymentMethod;
    }

    const url = 'http://localhost:8000/v1/stripe/create_customer'
    // Send paymentMethod to the backend
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        payment_method_id: paymentMethod.id,
        customer_email: email,
      }),
    });

    const result = await response.json();
    if (result.error) setError(result.error);
    else setSuccess('Payment method saved successfully!');
  };

  return (
    // <Elements stripe={stripePromise}>
      <form onSubmit={handleSubmit}>
        <label>Name</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        
        <label>Email</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

        <label>Payment Type</label>
        <select value={paymentType} onChange={handlePaymentTypeChange}>
          <option value="card">Credit Card</option>
          <option value="ach">ACH (Bank Account)</option>
        </select>

        {paymentType === 'card' ? (
          <CardElement />
        ) : (
          <IbanElement options={{ supportedCountries: ['US'] }} />
        )}

        <button type="submit" disabled={!stripe}>Save Payment Method</button>
        
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {success && <div style={{ color: 'green' }}>{success}</div>}
      </form>
    // </Elements>
  );
};



// import React, { useState, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe("your-publishable-key");

export const ClientPaymentMethods = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.down("md"));
  const { default_payment_id, payment_methods } = useSelector((state) => state.user.user_subscription);

  return (
    <Box>
      {payment_methods.length === 0 ? (
        <Typography variant="h4">No payment methods found.</Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: bp ? "column" : "row", flexWrap: "wrap", gap: 2 }}>
          {payment_methods.map((method) => {
            const { brand, last4, exp_month, exp_year, id, stripe_payment_id } = method;

            return (
              <CreditCardPreview brand={brand} last4={last4} expMonth={exp_month} expYear={exp_year} isDefault={id == default_payment_id} stripe_id={stripe_payment_id} db_id={id}/>
            );
          })}
        </Box>
      )}
    </Box>
  );
};


const CreditCardPreview = ({ brand, last4, expMonth, expYear, isDefault, stripe_id, db_id }) => {
  const dispatch = useDispatch();
  // const brandLogo = {
  //   visa: "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png",
  //   mastercard: "https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg",
  //   amex: "https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg",
  //   default: "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg",
  // };

  // const getBrandLogo = (brand) => {
  //   const key = brand.toLowerCase();
  //   return brandLogo[key] || brandLogo.default;
  // };
  const submitAction = async () => {
    try {
      const payload = {
        stripe_id: stripe_id,
        db_id: db_id
      }
      await dispatch(updateDefaultPaymentId(payload)).unwrap();
      dispatch(rehydrateUser());
      // setFeedback({ passed: true, comp: <FeedBack passed={true} message="Categories updated!" color="black"/> });
    } catch (exception) {
      // setFeedback({ passed: false, comp: <FeedBack passed={false} message="Update Failed. Please try again." color="black"/> });
    }
  }

  return (
    <Card
      sx={{
        width: 350,
        background: "linear-gradient(135deg, #1e3c72, #2a5298)",
        color: "#fff",
        borderRadius: "15px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        position: "relative",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {brand}
          </Typography>
          {/* <img
            src={getBrandLogo("default")}
            alt={`${brand} logo`}
            style={{ height: "40px" }}
          /> */}
        </Box>
        <Typography
          variant="h5"
          sx={{
            letterSpacing: "2px",
            fontFamily: "'Roboto Mono', monospace",
            mb: 2,
          }}
        >
          x{last4}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
          }}
        >
          <Typography variant="body2">EXP: {expMonth}/{expYear}</Typography>
          {/* <Typography variant="body2">CARD HOLDER</Typography> */}
        </Box>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        {isDefault ? 
          <Stack direction="row" spacing={1} alignSelf="center" border="1px solid #00e676" p={.5} borderRadius="8px">
            <Typography fontWeight={600} color="#00e676">Default Method</Typography>
            <CheckCircleIcon sx={{color:"#00e676"}}/>
          </Stack> :
        <Button variant="contained" color="primary" fullWidth onClick={submitAction}>
          Make Default
        </Button>
        }
      </CardActions>
    </Card>
  );
};
