import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import { Box, Button, Checkbox, CssBaseline, Fade, FormControlLabel, Modal, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography, IconButton, InputAdornment } from "@mui/material";

import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";

import { checkPassword, isEmail, isNullOrEmpty, min8Characters, hasUpperCase, hasLowerCase, hasNumber } from "../services/utilities";

//Redux
import { loginUser, forgotPassword } from "../redux/slices/user-slice";

// icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const loginSingupStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: alpha("#191970", 0.93),
  boxShadow: 5,
  // p: 4,
};

const light = {
  palette: {
    mode: "light",
  },
};

export default function LoginRegister({ handleClose, isOpen }) {
  return (
    // <Box sx={{ position: "fixed" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => handleClose()}
        // onClick={handleLoginClose}
        closeAfterTransition
        slotProps={{
          backdrop: { timeout: 300 },
        }}>
        <Fade in={isOpen}>
          <Box sx={loginSingupStyles}>
            <LoginSignupTabs />
          </Box>
        </Fade>
      </Modal>
    // </Box>
  );
}


const LoginSignupTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, display: "flex", justifyContent: "center" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
          <Tab label={<Typography color="white" fontSize="1.25rem" fontWeight="600">Sign In</Typography>} />
          {/* <Tab label="Register" sx={{ fontSize: "1.25rem" }} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Fade in>
          <Box>
            <Login />
          </Box>
        </Fade>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <Fade in>
          <Box>
            <SignUp />
          </Box>
        </Fade>
      </TabPanel> */}
    </Box>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  const [forgotMode, setForgotMode] = useState(false);

  useEffect(() => {
    if (email && password && isEmail(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, password]);

  const loginClick = async () => {
    const payload = {
      userInfo: {
        email: email,
        password: password,
      },
      rememberUser: rememberMe,
    };

    try {
      setDisabled(true);
      await dispatch(loginUser(payload)).unwrap();
      setDisabled(false);
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} color="white" message="Sign-in failed. Please try again" mt={1} /> });
      setDisabled(false);
    }

  };

  return (
    <React.Fragment>
      <ThemeProvider theme={createTheme(light)}>
        <CssBaseline />
        <Paper elevation={3} sx={{ p: 4, background: "white" }}>
          {forgotMode ? <ForgotPassword /> :
          
          <Stack component="form" spacing={2}>
            <TextField required fullWidth label="E-mail" variant="standard" autoComplete="email" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
            <PasswordField
              required
              variant="standard"
              label="Password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => (e.code === "Enter" ? loginClick() : null)}
            />
            <FormControlLabel
              control={<Checkbox checked={rememberMe} />}
              label="Remember me"
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
            />
            <Typography variant="subtitle1" color="steelblue"  sx={{ mt: 1, alignSelf: 'center', '&:hover': {cursor: 'pointer'}, fontWeight: 600, fontSize:'.75rem' }} onClick={()=>setForgotMode(true)}>
              Forgot Password
            </Typography>
          </Stack>
          }
        </Paper>
      </ThemeProvider>
      {!forgotMode && <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%", "&:disabled": { bgcolor: "gray" } }} onClick={loginClick}>
        Sign In
      </Button>}
      {feedBack.passed !== null && feedBack.comp}
    </React.Fragment>
  );
};

const SignUp = () => {
  // const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });
  const [isPwVisible, setIsPwVisible] = useState(false);

  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(email) || !isEmail(email) || !checkPassword(password)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, email, password]);

  const registerClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    // try {
    //   setDisabled(true);
    //   await dispatch(registerUser(payload)).unwrap();
    //   setFeedback({ passed: true, comp: <FeedBack passed={true} message="Success!" mt={1} /> });
    //   setDisabled(false);
    // } catch (exception) {
    //   let msg = "Registeration failed. Please try again";
    //   if (exception.detail == "email already registered") {
    //     msg = "Email already registered, please sign in";
    //   }
    //   setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
    //   setDisabled(false);
    // }
  };

  return (
    <>
      {/* <ThemeProvider theme={createTheme(light)}> */}
        {/* <CssBaseline /> */}
        <Paper elevation={3} sx={{ p: 4 }}>
          <Stack spacing={2} component="form">
            <Stack direction="row" spacing={1}>
              <TextField required label="First name" variant="standard" fullWidth onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name" />
              <TextField required label="Last name" variant="standard" fullWidth onChange={(e) => setLastName(e.target.value)} autoComplete="family-name" />
            </Stack>
            <TextField required fullWidth autoComplete="email" label="E-mail" variant="standard" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
            {/* <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />}>
              <Box> */}
            <PasswordField required variant="standard" label="Password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)}  onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)} onKeyPress={(e) => (e.code === "Enter" ? registerClick() : null)} />
              {/* </Box>
            </Tooltip> */}
          </Stack>
        </Paper>
      {/* </ThemeProvider> */}
      {/* <Typography variant="subtitle1">Privacy is one of ours top concerns, we will never sell your data.</Typography> */}
      <Button variant="contained" size="large" disabled={isDisabled} sx={{ mt: 3, width: "100%", "&:disabled": { bgcolor: "gray" } }} onClick={registerClick}>
        Register
      </Button>
      {feedBack.passed !== null && feedBack.comp}
    </>
  );
};


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const FeedBack = ({passed, message, ...props}) => {
  return (
    <Fade in><Box>
      <Stack spacing={.5} direction="row" alignItems="center" {...props}>
        <ValidationIcon isValid={passed} /><Typography variant="subtitle2">{message}</Typography>
      </Stack>
    </Box></Fade>
  );
}

const ValidationIcon = ({isValid}) => (
  isValid ? <CheckCircleOutlineIcon sx={{color: "lightgreen"}} fontSize="small" /> :  <ErrorOutlineIcon color="error" fontSize="small" />
);


const PasswordRequirements = ({value}) => {
  return(
    <Stack spacing={.5} sx={{my: 1, color: "white", fontFamily: "Roboto, sans-serif"}}>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={min8Characters(value)} /><Typography variant="subtitle2">Atleast 8 characters</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasLowerCase(value)} /><Typography variant="subtitle2">Contains lower case</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasUpperCase(value)} /><Typography variant="subtitle2">Contains upper case</Typography></Stack>
      <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasNumber(value)} /><Typography variant="subtitle2">Contains a digit</Typography></Stack>
      {/* <Stack spacing={.5} direction="row" alignItems="center"><ValidationIcon isValid={hasSpecialCharacter(value)} /><Typography variant="subtitle2">Contains a @$!%*?&#^)( </Typography></Stack> */}
    </Stack>
  );
}

export const PasswordField = (props) => {
  const { showRequirements } = props;
  const [pw, setPw] = useState(null)
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Tooltip disableHoverListener open={showPassword} placement="top" title={<PasswordRequirements value={pw} />}>
      <Stack direction="column">
        <TextField
          type={showPassword ? "text" : "password"}
          sx={{ mb: "1rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...props}
          onChange={(e) =>{
            setPw(e.target.value);
            props.onChange(e);
          }}
        />
        {showRequirements && <Stack direction="row" mt={1}><Typography variant="subtitle1" color="grey" fontWeight="600">8 Letters, atleast 1 Upper, 1 Lower, 1 Digit</Typography></Stack>}
      </Stack>
    </Tooltip>
  );
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [isDisabled, setDisabled] = useState(true);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if (isEmail(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email]);

  const forgotPW = async () => {
    const payload = {
      email: email
    };
    
    try {
      setDisabled(true);
      await dispatch(forgotPassword(payload)).unwrap();
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Password reset email sent!" mt={1} /> });
    } catch (exception) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Something went wrong, please try again" mt={1} /> });
      setDisabled(false);
    }
  };

  return(
    <>
      <Stack>
        <TextField required fullWidth label="E-mail" variant="standard" autoComplete="email" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" size="small" disabled={isDisabled}  sx={{ mt: 3, width: "100%" }} onClick={forgotPW}>
          Send E-Mail
        </Button>
      </Stack>
      {feedBack.passed !== null && feedBack.comp}
    </>
  );
}