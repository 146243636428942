import React, { useEffect, useState } from "react";
import { Typography, TextField, Box, Fade, Stack } from "@mui/material";

// icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export const PhoneNumberInput = ({ initialNum = "", setPhoneNumber, ...props }) => {
  const [num, setNum] = useState();
  const [disNum, setDisNum] = useState()

  useEffect(() => {
    if (initialNum !== num) {
      onPhoneNumberChanged(initialNum);
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(num);
  }, [num]);

  const onPhoneNumberChanged = (phone_number) => {
    if (phone_number === null || phone_number === "" || (isNaN(phone_number) && phone_number.length === 1) || phone_number.length === 0) {
      setDisNum("");
      setNum(null);
      return;
    }

    let num = phone_number.match(/\d+/g).join("");
    const numberLength = num.length;
    if (numberLength > 0) {
      if (numberLength > 0 && numberLength <= 3) {
      } else if (numberLength >3 && numberLength <=6) {
        num = `(${num.slice(0,3)}) ${num.slice(3,6)}`;
      } else if (numberLength > 6 && numberLength <= 10) {
        num = `(${num.slice(0,3)}) ${num.slice(3,6)} ${num.slice(6,10)}`;
      } else {
        num = `(${num.slice(0,3)}) ${num.slice(3,6)} ${num.slice(6,10)}`;
      }
      setDisNum(num);
      setNum(num.match(/\d+/g).join(""));
    }
    
  };
  return (
    <TextField name="phone"  variant="standard" value={disNum} label={<Typography fontSize="1rem" fontWeight="600" px={1}>Phone*</Typography>} onChange={(e) => onPhoneNumberChanged(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", px: 1 }} {...props} />
  );
}

export const LabeledBox = ({ label, children, labelBGC = 'white' }) => {
  // fontSize: "0.75em",
  return (
    <Box position="relative">
      <Typography variant="h6" style={{marginLeft: "0.71em", marginRight: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 1, position: "absolute", backgroundColor: labelBGC, textWrap: "nowrap"}}>{label}</Typography>
      <Box sx={{ borderRadius: 2, border: 1, borderColor: 'grey.600' }}>
        {children}
      </Box>
    </Box>
  );
}

export const FeedBack = ({passed, message, ...props}) => {
  return (
    <Fade in><Box>
      <Stack spacing={.5} direction="row" alignItems="center" {...props}>
        <ValidationIcon isValid={passed} /><Typography variant="subtitle2" color="white" {...props}>{message}</Typography>
      </Stack>
    </Box></Fade>
  );
}

const ValidationIcon = ({isValid}) => (
  isValid ? <CheckCircleOutlineIcon sx={{color: "lightgreen"}} fontSize="small" /> :  <ErrorOutlineIcon color="error" fontSize="small" />
);