import React, { useEffect, useState } from "react";

import _ from 'underscore';

import { Box, Card, Paper, Stack, Typography, Button, Container, Divider,
  TextField, MenuItem, FormControl, InputLabel, Select, RadioGroup,
  Radio, FormControlLabel, List, ListItem, ListItemIcon } from "@mui/material";
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';


// Redux
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/slices/user-slice";
import { modCreateLead, modFindClient } from "../redux/slices/lead-slice";

import { PhoneNumberInput, LabeledBox, FeedBack } from "../components/CustomComponents";

// Icon
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';

import { ABBR_TO_STATE, QUALIFICATION } from "../services/constants";
import { isNullOrEmpty, verifyPhoneNumber } from "../services/utilities";

export default function ModeratorPanel() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [clKey, setClKey] = useState(new Date().getTime());

  return (
    <Box sx={{
      minHeight: "100vh",
      height: "100%",
      display: "flex",
      flexDirection: "row"
    }}>
      <MenuNavList selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}  />
      <Container maxWidth='md'>
        <Stack mt={4} spacing={3}>
          <Typography variant="h2">Moderator Panel</Typography>
          <Divider flexItem sx={{ border: '2px solid' }} />
          {selectedIndex === 0 && <CreateLead key={clKey} reset={() => {
            setClKey(new Date().getTime());
          }}/>}
        </Stack>
      </Container>
    </Box>
  );
}

const dark = {
  palette: {
    mode: "dark",
  },
};

const ClientCard = ({details}) => {
  if (details == null) {
    return (
      <ThemeProvider theme={createTheme(dark)}>
        <Paper sx={{ p: 2 }}>
          <Card>
            <Typography variant="h5">No Client Currently Available</Typography>
          </Card>
        </Paper>
      </ThemeProvider>
    )
  }
  return(
    <ThemeProvider theme={createTheme(dark)}>
      <Paper sx={{ p: 2, borderRadius: 3 }}>
        <Card>
          <Stack spacing={1}>
            <Typography variant="h5" fontWeight="600" alignSelf="center">Client Information</Typography>
            <Typography variant="h6" fontWeight="600">Name: {details['first_name']} {details['last_name']}</Typography>
            <Typography variant="h6" fontWeight="600">Firm: {details['business_name']}</Typography>
            <Typography variant="h6" fontWeight="600">Phone: {`(${details['phone'].slice(0,3)}) ${details['phone'].slice(3,6)} ${details['phone'].slice(6,10)}`}</Typography>
            <Typography variant="h6" fontWeight="600">Email: {details['email']}</Typography>
          </Stack>
        </Card>
      </Paper>
    </ThemeProvider>
  );
}


const CreateLead = ({reset}) => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [email, setEmail] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || !verifyPhoneNumber(phoneNumber) || isNullOrEmpty(selectedCategory) || isNullOrEmpty(selectedLocation) || isNullOrEmpty(selectedQualification)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  },[firstName, lastName, phoneNumber, selectedCategory, selectedLocation, selectedQualification]);

  const findLawyer = async () => {
    try {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        email: email ? email : null,
        category_id: Number(selectedCategory),
        qualification: selectedQualification,
        state_abbr: selectedLocation['state'],
        county: selectedLocation['county'],
        region_id: Number(selectedLocation['region_id']),
      }

      const resp = await dispatch(modFindClient(payload)).unwrap();
      setClientDetails(resp)
      setShowDetails(true);
    } catch (e) {
    }
  }

  const submitLead = async () => {
    try {
      const lead_info = {
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        email: email ? email : null,
        category_id: Number(selectedCategory),
        qualification: selectedQualification,
        state_abbr: selectedLocation['state'],
        county: selectedLocation['county'],
        region_id: Number(selectedLocation['region_id']),
      }

      const payload = {
        lead_info: lead_info,
        assign_to: clientDetails.client_id
      }

      await dispatch(modCreateLead(payload)).unwrap();
      setClientDetails(null)
      setShowDetails(false);
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Lead Assigned!" color="black"/> });
      setTimeout(() => {
        reset();
      }, 1000);
    } catch (e) {
      setFeedback({ passed: false, comp: <FeedBack passed={false} message="Lead Assignment failed. Please try again." color="black"/> });
    }
  }
  

  return (    
    <Stack spacing={3}>
      <Stack>
        <Typography variant="h3">Create Lead</Typography>
        <Typography variant="subtitle2" fontWeight="600">(*) designates required field</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField name="firstName" fullWidth label={<Typography fontSize="1rem" fontWeight="600" px={1}>First Name*</Typography>} variant="standard" onChange={(e) => setFirstName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
        <TextField name="lastName" fullWidth label={<Typography fontSize="1rem" fontWeight="600" px={1}>Last Name*</Typography>} variant="standard" onChange={(e) => setLastName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
      </Stack>
      <PhoneNumberInput setPhoneNumber={setPhoneNumber} />
      <TextField name="email" label={<Typography fontSize="1rem" fontWeight="600" px={1}>Email</Typography>} variant="standard" onChange={(e) => setEmail(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
      <SetLeadCategory setCategory={setSelectedCategory} />
      <SetLeadLocation setLocation={setSelectedLocation} />
      <LeadQualified setQualification={setSelectedQualification} />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button disabled={submitDisabled} variant="contained" size="large" onClick={findLawyer}>Find Lawyer</Button>
        <Button disabled={clientDetails == null} variant="contained" size="large" onClick={submitLead}>Assign Lead</Button>
      </Stack>
      {feedBack.passed !== null && feedBack.comp}
      {showDetails && <ClientCard details={clientDetails} />}
    </Stack>
  );
}

const SetLeadCategory = ({ setCategory }) => {
  const { categories } = useSelector((state) => state.lead);
  const [leadCategory, setLeadCategory ] = useState('');

  const handleChange = (event) => {
    const category = event.target.value;
    setLeadCategory(category);
    setCategory(categories.find(cat => cat.category === category).id);
  };

  return (
    <Box sx={{ minWidth: 120, }}>
      <Typography fontSize="1rem" fontWeight="600" px={1} color="grey.600" mb={1.5}>Category*</Typography>
      <FormControl fullWidth>
        <InputLabel sx={{bgcolor: "white"}}>Select Category</InputLabel>
        <Select
          value={leadCategory}
          label="Category"
          onChange={handleChange}
        >
          {categories && categories.map((cat) => {
            const { category, is_disabled } = cat;
            if (is_disabled) {
              return;
            }
            return (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

const SetLeadLocation = ({ setLocation }) => {
  const [leadState, setLeadState ] = useState("");
  const [leadCounty, setLeadCounty ] = useState("");

  const geo_regions = useSelector((state) => state.lead.regions);
  const state_list = Object.keys(geo_regions);
  const [region_list, set_region_list] = useState([]);
  
  useEffect(() => {
    let tmp_region_list = [];
    if (leadState) {
      const selected_region_ids = Object.keys(geo_regions[leadState]);
      selected_region_ids.forEach(id => {
        geo_regions[leadState][id].forEach(cty => {
          tmp_region_list.push({id: id, county: cty});
        })
      });
      set_region_list(tmp_region_list);
    }

  }, [leadState]);


  const handleStateChange = (event) => {
    const state = event.target.value;
    if (state !== leadState) {
      setLeadState(state);
      setLocation(null);
      setLeadCounty("");
    }
    
  };

  const handleCountyChange = (event) => {
    const selectedCounty = event.target.value;
    if (selectedCounty) {
      setLeadCounty(selectedCounty);
      setLocation({
        region_id: region_list.find( cty => cty.county == selectedCounty).id,
        county: selectedCounty,
        state: leadState
      });
    }
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <Typography fontSize="1rem" fontWeight="600" px={1} color="grey.600" mb={1.5}>State*</Typography>
      <FormControl fullWidth>
        <InputLabel sx={{bgcolor: "white"}}>Select State</InputLabel>
        <Select
          value={leadState}
          label="State"
          onChange={handleStateChange}
        >
          {state_list && state_list.map((name) => {
            return (
              <MenuItem key={name} value={name}>{ABBR_TO_STATE[name]}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Typography fontSize="1rem" fontWeight="600" px={1} mt={2} color="grey.600" mb={1.5}>County*</Typography>
      <FormControl fullWidth>
        <InputLabel sx={{bgcolor: "white"}}>Select County</InputLabel>
        <Select
          value={leadCounty}
          label="County"
          onChange={handleCountyChange}
        >
          {region_list && region_list.map((cty) => {
            const { county } = cty;
            return (
              <MenuItem key={county} value={county}>{county}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

const LeadQualified = ({ setQualification }) => {
  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    const qualification = event.target.value;
    setValue(qualification);
    setQualification(qualification);
  };

  return (
    <LabeledBox label="Is Lead Qualified?*">
      <FormControl sx={{ mt: 1.5, px: 1 }}>
        <RadioGroup
          aria-labelledby="set-lead-qualification"
          name="qualification-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value={QUALIFICATION.STANDARD} control={<Radio />} label="Standard Lead" />
          <FormControlLabel value={QUALIFICATION.QUALIFIED} control={<Radio />} label="Qualified Lead" />
          <FormControlLabel disabled value={QUALIFICATION.COSMIC} control={<Radio />} label="Cosmic Qualified Lead" />
        </RadioGroup>
      </FormControl>
    </LabeledBox>
  );
}


const MENU_HOVER_COLOR = "#4fc3f7";
export const MenuListItem = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  cursor: "pointer",
}));

const MenuNavList = ({ selectedIndex, setSelectedIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#1769aa' }}>
      <List component="nav" aria-label="menu list">
        <MenuListItem
          selected={selectedIndex === 0}
          onClick={(e) => handleListItemClick(e,0)}
          sx={{
            ...(selectedIndex === 0) && {
              bgcolor: MENU_HOVER_COLOR
            },
            "&:hover" : {
              bgcolor: MENU_HOVER_COLOR
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon fontSize="large" sx={{ color: "white" }}/>
          </ListItemIcon>
          <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Create Lead</Typography>
        </MenuListItem>
        {/* <Divider sx={{ my: 2}} /> */}
        {/* <MenuListItem
          selected={selectedIndex === 1}
          onClick={(e) => handleListItemClick(e,1)}
          sx={{
            ...(selectedIndex === 1) && {
              bgcolor: MENU_HOVER_COLOR
            },
            "&:hover" : {
              bgcolor: MENU_HOVER_COLOR
            },
          }}
        >
          <ListItemIcon>
            <ManageAccountsIcon fontSize="large" sx={{ color: "white" }}/>
          </ListItemIcon>
          <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Unassigned Leads</Typography>
        </MenuListItem> */}
        {/* <MenuListItem
          selected={selectedIndex === 2}
          onClick={(e) => handleListItemClick(e,2)}
          sx={{
            ...(selectedIndex === 2) && {
              bgcolor: MENU_HOVER_COLOR
            },
            "&:hover" : {
              bgcolor: MENU_HOVER_COLOR
            },
          }}
        >
          <ListItemIcon>
            <ManageAccountsIcon fontSize="large" sx={{ color: "white" }}/>
          </ListItemIcon>
          <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Settings</Typography>
        </MenuListItem> */}
        <Divider sx={{ mb: 4}} />
        <MenuListItem
          selected={selectedIndex === 3}
          onClick={() => dispatch(logOut())}
          sx={{
            "&:hover" : {
              bgcolor: MENU_HOVER_COLOR
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="large" sx={{ color: "white" }}/>
          </ListItemIcon>
          <Typography sx={{ fontWeight: 700, fontSize: "1.2rem", color: "white" }}>Logout</Typography>
        </MenuListItem>
      </List>
    </Box>
  );
}