import React, { useEffect, useState } from "react";

import { Container, Stack, Typography } from "@mui/material";






export const PolicyPage = () => {
  return (
    <Container maxWidth="md">
      <Stack direction="column" spacing={2} sx={{ overflowY: "auto"}}>
        <Typography variant="h3" fontWeight={600}>Privacy Policy</Typography>
        {privacy_policy.map(item => {
          const { title, para } = item;
          return (
            <>
              <Typography variant="h5" fontWeight={600}>{title}</Typography>
              {para.map(policy => {
                return (
                  <Typography variant="p">{policy}</Typography>
                );
              })}
            </>
          );
        })}
      </Stack>
    </Container>
  );
}

export const ToS = () => {
  return (
    <Container maxWidth="md">
      <Stack direction="column" spacing={2}>
        <Typography variant="h3" fontWeight={600}>Terms of Service</Typography>
        {tos.map(item => {
          const { title, para } = item;
          return (
            <>
              <Typography variant="h5" fontWeight={600}>{title}</Typography>
              {para.map(policy => {
                return (
                  <Typography variant="p">{policy}</Typography>
                );
              })}
            </>
          );
        })}
      </Stack>
    </Container>
  );
}

const privacy_policy = [
  {
    title: "Opting In for Free Information",
    para: [
      'By requesting an offer or opting in you are granting permission to be contacted by email and/or telephone regarding the offer. If you are not interested in receiving communications from the company represented in the advertisement, you are advised not to respond to the advertisement.',
      'The contact information you provide in response to an offer will be used in the following manner: ',
      'To share your information with the company affiliated with the ad. This company may contact you, by email and/or phone, to provide you more information about the offer advertised.',
    ],
  },
  {
    title: "What information do we collect?",
    para: [
      'We collect information from you when you register on our site, place an order, subscribe to our newsletter, respond to a survey or fill out a form.',
      'When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number or credit card information. You may, however, visit our site anonymously.',
      'Google, as a third party vendor, uses cookies to serve ads on your site. Google’s use of the DART cookie enables it to serve ads to your users based on their visit to your sites and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.',
    ],
  },
  {
    title: "What Do We Use Your Information For?",
    para: [
      'Any of the information we collect from you may be used in one of the following ways:',
      'To personalize your experience (your information helps us to better respond to your individual needs)',
      'To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you)',
      'To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)',
      'To process transactions. Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.',
      'To send periodic emails. The email address you provide for order processing, will only be used to send you information and updates pertaining to your order.',
      'If you decide to opt-in to our mailing list, you will receive emails that may include company news, updates, related product or service information, etc.',
      'note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.'
    ],
  },
  {
    title: "How Do We Protect Your Information?",
    para: [
      'We implement a variety of security measures to maintain the safety of your personal information when you place an order.',
      'We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.',
      'After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.',
    ],
  },
  {
    title: "Do We Use Cookies?",
    para: [
      'Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.',
      'We use cookies to help us remember and process the items in your shopping cart, understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.',
      'If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function properly. However, you can still place orders over the telephone or by contacting customer service.',
    ],
  },
  {
    title: "Do We Disclose Any Information To Outside Parties?",
    para: [
      'We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.'
    ],
  },
  {
    title: "Third Party Links",
    para: [
      'Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.'
    ],
  },
  {
    title: "Childrens Online Privacy Protection Act Compliance",
    para: [
      'We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.'
    ],
  },
  {
    title: "Terms of Service",
    para: [
      'Please also visit our Terms of Service policy establishing the service, use, disclaimers, and limitations of liability governing the use of our website by referencing the link located in the footer of our website.'
    ],
  },
  {
    title: "Your Consent",
    para: [
      'By using our site, you consent to our Privacy Policy.'
    ],
  },
  {
    title: "Changes To Our Privacy Policy",
    para: [
      'If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.'
    ],
  },
  {
    title: "Contacting Us",
    para: [
      'If there are any questions regarding this privacy policy you may contact us via our Contact Us page on this website.'
    ],
  }
]

const tos = [
  {
    title: "",
    para: [
      'IMPORTANT! THESE TERMS OF USE (TOU) GOVERN YOUR USE OF THIS SITE, WHICH IS PROVIDED BY OUR COMPANY. BY ACCESSING THIS SITE, YOU ARE INDICATING YOUR ACKNOWLEDGMENT AND ACCEPTANCE OF THESE TERMS OF USE. THESE TERMS OF USE ARE SUBJECT TO CHANGE BY OUR COMPANY AT ANY TIME IN ITS DISCRETION. YOUR USE OF THIS SITE AFTER SUCH CHANGES ARE IMPLEMENTED CONSTITUTES YOUR ACKNOWLEDGMENT AND ACCEPTANCE OF THE CHANGES. PLEASE CONSULT THESE TERMS OF USE PRIOR TO EVERY USE FOR ANY CHANGES.',
    ],
  },
  {
    title: "Access To This Site",
    para: [
      'YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THIS WEB SITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS THIS WEB SITE FOR ANY REASON. DUE TO THE AGE RESTRICTIONS FOR USE OF THIS WEB SITE, NO INFORMATION OBTAINED BY THIS WEB SITE, FALLS WITHIN THE CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) AND IS NOT MONITORED AS DOING SO.',
      'To access this site or some of the resources it has to offer, you may be asked to provide certain registration details or other information. It is a condition of your use of this site that all the information you provide on this site will be correct, current, and complete. If our Company believes the information you provide is not correct, current, or complete, we have the right to refuse you access to this site or any of its resources, and to terminate or suspend your access at any time, without notice.',
    ],
  },
  {
    title: "Restrictions On Use",
    para: [
      'You may use this site for purposes expressly permitted by this site. You may not use this site for any other purpose, including any commercial purpose, without our Company‘s express prior written consent. For example, you may not (and may not authorize any other party to) (i) co-brand this site, or (ii) frame this site, or (iii) hyper-link to this site, without the express prior written permission of an authorized representative of our Company. For purposes of these Terms of Use, “co-branding” means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner as is reasonably likely to give a user the impression that such other party has the right to display, publish, or distribute this site or content accessible within this site. You agree to cooperate with our Company in causing any unauthorized co-branding, framing or hyper-linking immediately to cease.',
    ],
  },
  {
    title: "Proprietary Information",
    para: [
      'The material and content (hereinafter referred to as the “Content”) accessible from this site, and any other World Wide Web site owned, operated, licensed, or controlled by our Company is the proprietary information of our Company or the party that provided the Content to our Company, and our Company or the party that provided the Content to our Company retains all right, title, and interest in the Content. Accordingly, the Content may not be copied, distributed, republished, uploaded, posted, or transmitted in any way without the prior written consent of our Company, or unless authorized in writing elsewhere on our site, except that you may print out a copy of the Content solely for your personal use. In doing so, you may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. Modification or use of the Content except as expressly provided in these Terms of Use violates our Company‘s intellectual property rights. Neither title nor intellectual property rights are transferred to you by access to this site.',
    ],
  },
  {
    title: "Hyper-Links",
    para: [
      'This site may be hyper-linked to other sites which are not maintained by, or related to, our Company. Hyper-links to such sites are provided as a service to users and are not sponsored by or affiliated with this site or our Company. Our Company has not reviewed any or all of such sites and is not responsible for the content of those sites. Hyper-links are to be accessed at the user’s own risk, and our Company makes no representations or warranties about the content, completeness or accuracy of these hyper-links or the sites hyper-linked to this site. Further, the inclusion of any hyper-link to a third-party site does not necessarily imply endorsement by our Company of that site.',
    ],
  },
  {
    title: "Submissions",
    para: [
      'You hereby grant to our Company the royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display all content, remarks, suggestions, ideas, graphics, or other information communicated to our Company through this site (together, hereinafter known as the “Submission”), and to incorporate any Submission in other works in any form, media, or technology now known or later developed. Our Company will not be required to treat any Submission as confidential, and may use any Submission in its business (including without limitation, for products or advertising) without incurring any liability for royalties or any other consideration of any kind, and will not incur any liability as a result of any similarities that may appear in future Company operations.',
      'Our Company will treat any personal information that you submit through this site in accordance with its Privacy Policy as set forth on this site.'
    ],
  },
  {
    title: "Disclaimer",
    para: [
      'You understand that our Company cannot and does not guarantee or warrant that files available for downloading from the Internet will be free of viruses, worms, Trojan horses or other code that may manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to this site for the reconstruction of any lost data. Our Company does not assume any responsibility or risk for your use of the Internet.',
      'The Content is not necessarily complete and up-to-date and should not be used to replace any written reports, statements, or notices provided by Company. Investors, borrowers, and other persons should use the Content in the same manner as any other educational medium and should not rely on the Content to the exclusion of their own professional judgment. Information obtained by using this site is not exhaustive and does not cover all issues, topics, or facts that may be relevant to your goals.',
      'YOUR USE OF THIS SITE IS AT YOUR OWN RISK. The Content is provided “as is” and without warranties of any kind, either expressed or implied. Our Company disclaims all warranties, including any implied warranties of merchantability, fitness for a particular purpose, TITLE, OR NON-INFRINGEMENT. Our Company does not warrant that the functions OR CONTENT contained in this site will be uninterrupted or error-free, that defects will be corrected, or that this site or the server that makes it available are free of viruses or other harmful components. Our Company does not warrant or make any representation regarding use, or the result of use, of the content in terms of accuracy, reliability, or otherwise. The content may include technical inaccuracies or typographical errors, and Company may make changes or improvements at any time. You, and not our Company, assume the entire cost of all necessary servicing, repair or correction IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. Our COMPANY MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.',
      'All of the information in this site, whether historical in nature or forward-looking, speaks only as of the date the information is posted on this site, and Company does not undertake any obligation to update such information after it is posted or to remove such information from this site if it is not, or is no longer, accurate or complete.',
    ],
  },
  {
    title: "Limitation On Liability",
    para: [
      'COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE COLLECTIVE LIABILITY OF COMPANY AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO COMPANY FOR THE APPLICABLE CONTENT, PRODUCT OR SERVICE OUT OF WHICH LIABILITY AROSE.',
    ],
  },
  {
    title: "Indemnity",
    para: [
      'You will indemnify and hold Company, its subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors (hereinafter known as the “Indemnified Parties”) harmless from any breach of these Terms of Use by you, including any use of Content other than as expressly authorized in these Terms of Use. You agree that the Indemnified Parties will have no liability in connection with any such breach or unauthorized use, and you agree to indemnify any and all resulting loss, damages, judgments, awards, costs, expenses, and attorney’s fees of the Indemnified Parties in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of your use of the information accessed from this site.',
    ],
  },
  {
    title: "Trademarks",
    para: [
      'Trademarks, service marks, and logos appearing in this site are the property of Company or the party that provided the trademarks, service marks, and logos to Company. Company and any party that provided trademarks, service marks, and logos to Company retain all rights with respect to any of their respective trademarks, service marks, and logos appearing in this site.',
    ],
  },
  {
    title: "Information You Provide",
    para: [
      'You may not post, send, submit, publish, or transmit in connection with this site any material that:',
      'you do not have the right to post, including proprietary material of any third party;',
      'advocates illegal activity or discusses an intent to commit an illegal act; ',
      'is vulgar, obscene, pornographic, or indecent;',
      'does not pertain directly to this site;',
      'threatens or abuses others, libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing, threatening or offensive;', 
      'seeks to exploit or harm children by exposing them to inappropriate content, asking for personally identifiable details or otherwise;',
      'infringes any intellectual property or other right of any entity or person, including violating anyone’s copyrights or trademarks or their rights of publicity;',
      'violates any law or may be considered to violate any law;',
      'impersonates or misrepresents your connection to any other entity or person or otherwise manipulates headers or identifiers to disguise the origin of the content;',
      'advertises any commercial endeavor (e.g., offering for sale products or services) or otherwise engages in any commercial activity (e.g., conducting raffles or contests, displaying sponsorship banners, and/or soliciting goods or services) except as may be specifically authorized on this site;',
      'solicits funds, advertisers or sponsors;',
      'includes programs which contain viruses, worms and/or Trojan horses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications;',
      'disrupts the normal flow of dialogue, causes a screen to scroll faster than other users are able to type, or otherwise act in a way which affects the ability of other people to engage in real time activities via this site;',      
      'includes MP3 format files;',
      'amounts to a “pyramid” or similar scheme;',
      'disobeys any policy or regulations established from time to time regarding use of this site or any networks connected to this site; or',
      'contains hyper-links to other sites that contain content that falls within the descriptions set forth above. ',
      'Although under no obligation to do so, our Company reserves the right to monitor use of this site to determine compliance with these Terms of Use, as well the right to remove or refuse any information for any reason. Notwithstanding these rights, you remain solely responsible for the content of your submissions. You acknowledge and agree that neither Company nor any third party that provides Content to Company will assume or have any liability for any action or inaction by Company or such third party with respect to any submission.'
    ],
  },
  {
    title: "Security",
    para: [
      'Any passwords used for this site are for individual use only. You will be responsible for the security of your password (if any). Company will be entitled to monitor your password and, at its discretion, require you to change it. If you use a password that Company considers insecure, Company will be entitled to require the password to be changed and/or terminate your account.',
      'You are prohibited from using any services or facilities provided in connection with this site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking tools or network probing tools) is strictly prohibited. If you become involved in any violation of system security, Company reserves the right to release your details to system administrators at other sites in order to assist them in resolving security incidents. Company reserves the right to investigate suspected violations of these Terms of Use.',
      'Company reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing Company to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate these Terms of Use.',
      'BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER [COMPANY] OR LAW ENFORCEMENT AUTHORITIES.'
    ],
  },
  {
    title: "Miscellaneous ",
    para: [
      'These Terms of Use will be governed and interpreted pursuant to the laws of Tennessee, United States of America, notwithstanding any principles of conflicts of law. You specifically consent to personal jurisdiction in Tennessee in connection with any dispute between you and Company arising out of these Terms of Use or pertaining to the subject matter hereof. The parties to these Terms of Use each agree that the exclusive venue for any dispute between the parties arising out of these Terms of Use or pertaining to the subject matter of these Terms of Use will be in the state and federal courts in Nashville, Tennessee. If any part of these Terms of Use is unlawful, void or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions. These Terms of Use constitute the entire agreement among the parties relating to this subject matter. Notwithstanding the foregoing, any additional terms and conditions on this site will govern the items to which they pertain. Company may revise these Terms of Use at any time by updating this posting.',
      'This policy is valid from November 25, 2024',
    ],
  },
];