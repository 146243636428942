import _ from "underscore";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL, authAxios, axiosConfig } from "../../services/client";

const coreState = {
    categories: [],
    regions: [],
    user_leads: [],
    price_list: [],
};
  
let initialState = {
...coreState,
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    getCategoriesCases(builder);
    getRegionsCases(builder);
    getClientLeadsCases(builder);
    getCategoryPricesCases(builder);
  },
});
  
  
export default leadSlice.reducer;

export const getCategories = createAsyncThunk(
  "lead/getCategories",
  async (rejectWithValue) => {
    try {
    const response = await authAxios.get(BASE_URL + "leads/categories", axiosConfig);
    return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
);

const getCategoriesCases = (builder) =>
  builder
    .addCase(getCategories.pending, (state) => {
    })
    .addCase(getCategories.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.categories = _.sortBy(action.payload, "id");
        } else {
          state.categories = action.payload;
        }

    })
    .addCase(getCategories.rejected, (state, action) => {
    });

export const getRegions = createAsyncThunk(
    "lead/getRegions",
    async (rejectWithValue) => {
      try {
          const response = await authAxios.get(BASE_URL + "leads/georegions", axiosConfig);
          return response.data;
      } catch (err) {
      return rejectWithValue(err.response.data);
      }
    }
    );
      
const getRegionsCases = (builder) =>
builder
    .addCase(getRegions.pending, (state) => {
    })
    .addCase(getRegions.fulfilled, (state, action) => {
        state.regions = action.payload.regions;
    })
    .addCase(getRegions.rejected, (state, action) => {
    });


export const modCreateLead = createAsyncThunk(
  "lead/modCreateLead",
  async (payload, { rejectWithValue }) => {
    try {
        const response = await authAxios.post(BASE_URL + "leads/mod_create", payload, axiosConfig);
        return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const modFindClient = createAsyncThunk(
  "lead/modFindClient",
  async (payload, { rejectWithValue }) => {
    try {
        const response = await authAxios.post(BASE_URL + "leads/find_client", payload, axiosConfig);
        return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClientLeads = createAsyncThunk(
  "lead/getClientLeads",
  async (rejectWithValue) => {
    try {
        const response = await authAxios.get(BASE_URL + "leads/client_leads", axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
    
const getClientLeadsCases = (builder) =>
builder
  .addCase(getClientLeads.pending, (state) => {
  })
  .addCase(getClientLeads.fulfilled, (state, action) => {
      state.user_leads = action.payload;
  })
  .addCase(getClientLeads.rejected, (state, action) => {
  });

  export const getCategoryPrices = createAsyncThunk(
    "lead/getCategoryPrices",
    async (rejectWithValue) => {
      try {
          const response = await authAxios.get(BASE_URL + "leads/category_price", axiosConfig);
          return response.data;
      } catch (err) {
      return rejectWithValue(err.response.data);
      }
    }
    );
      
  const getCategoryPricesCases = (builder) =>
  builder
    .addCase(getCategoryPrices.pending, (state) => {
    })
    .addCase(getCategoryPrices.fulfilled, (state, action) => {
        state.price_list = action.payload;
    })
    .addCase(getCategoryPrices.rejected, (state, action) => {
    });