import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BASE_URL, authAxios, axiosConfig } from "../../services/client";

const coreState = {
  plans: [],
  transactions: [],
};
  
let initialState = {
...coreState,
};
// let initialState = null;

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    createCustomerCases(builder);
    addPaymentMethodCases(builder);
    getSubPlansCases(builder);
    selectPlanCases(builder);
    rechargeAccountCases(builder);
    getPaymentMethodsCases(builder);
    updateDefaultPaymentIdCases(builder);
    getUserTransactionsCases(builder);
  },
});
  
  
export default paymentSlice.reducer;

export const createCustomer = createAsyncThunk(
  "payment/createCustomer",
  async (payload, { rejectWithValue }) => {
    try {
    const response = await authAxios.post(BASE_URL + "stripe/create_customer", payload, axiosConfig);
    return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const createCustomerCases = (builder) =>
  builder
    .addCase(createCustomer.pending, (state) => {
    })
    .addCase(createCustomer.fulfilled, (state, action) => {
        // state.categories = action.payload;
    })
    .addCase(createCustomer.rejected, (state, action) => {
    });

export const addPaymentMethod = createAsyncThunk(
    "payment/addPaymentMethod",
    async (payload, {rejectWithValue}) => {
      try {
          const response = await authAxios.post(BASE_URL + "stripe/payment_method", payload, axiosConfig);
          return response.data;
      } catch (err) {
      return rejectWithValue(err.response.data);
      }
    }
    );
      
const addPaymentMethodCases = (builder) =>
  builder
    .addCase(addPaymentMethod.pending, (state) => {
    })
    .addCase(addPaymentMethod.fulfilled, (state, action) => {
        // state.regions = action.payload.regions;
    })
    .addCase(addPaymentMethod.rejected, (state, action) => {
    });

export const getSubPlans = createAsyncThunk(
      "payment/getSubPlans",
      async (rejectWithValue) => {
        try {
            const response = await authAxios.get(BASE_URL + "stripe/plans", axiosConfig);
            return response.data;
        } catch (err) {
        return rejectWithValue(err.response.data);
        }
      }
      );
        
const getSubPlansCases = (builder) =>
  builder
    .addCase(getSubPlans.pending, (state) => {
    })
    .addCase(getSubPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
    })
    .addCase(getSubPlans.rejected, (state, action) => {
    });

export const selectPlan = createAsyncThunk(
  "payment/selectPlan",
  async (payload, { rejectWithValue }) => {
    try {
        const response = await authAxios.post(BASE_URL + "stripe/select_plan", payload, axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
        
const selectPlanCases = (builder) =>
  builder
    .addCase(selectPlan.pending, (state) => {
    })
    .addCase(selectPlan.fulfilled, (state, action) => {
    })
    .addCase(selectPlan.rejected, (state, action) => {
    });
    

export const rechargeAccount = createAsyncThunk(
  "payment/rechargeAccount",
  async (rejectWithValue) => {
    try {
        const response = await authAxios.get(BASE_URL + "stripe/recharge_plan", axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
        
const rechargeAccountCases = (builder) =>
  builder
    .addCase(rechargeAccount.pending, (state) => {
    })
    .addCase(rechargeAccount.fulfilled, (state, action) => {
    })
    .addCase(rechargeAccount.rejected, (state, action) => {
    });

// /update_default_payment/{payment_id}

export const getPaymentMethods = createAsyncThunk(
  "payment/getPaymentMethods",
  async (rejectWithValue) => {
    try {
        const response = await authAxios.get(BASE_URL + `stripe/payment_methods`, axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
        
const getPaymentMethodsCases = (builder) =>
  builder
    .addCase(getPaymentMethods.pending, (state) => {
    })
    .addCase(getPaymentMethods.fulfilled, (state, action) => {
    })
    .addCase(getPaymentMethods.rejected, (state, action) => {
    });

export const updateDefaultPaymentId = createAsyncThunk(
  "payment/updateDefaultPaymentId",
  async (payload,{ rejectWithValue }) => {
    try {
        const response = await authAxios.post(BASE_URL + `stripe/update_default_payment`, payload, axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
        
const updateDefaultPaymentIdCases = (builder) =>
  builder
    .addCase(updateDefaultPaymentId.pending, (state) => {
    })
    .addCase(updateDefaultPaymentId.fulfilled, (state, action) => {
    })
    .addCase(updateDefaultPaymentId.rejected, (state, action) => {
    });

export const getUserTransactions = createAsyncThunk(
  "payment/getUserTransactions",
  async (rejectWithValue) => {
    try {
        const response = await authAxios.get(BASE_URL + `client/transactions`, axiosConfig);
        return response.data;
    } catch (err) {
    return rejectWithValue(err.response.data);
    }
  }
  );
        
const getUserTransactionsCases = (builder) =>
  builder
    .addCase(getUserTransactions.pending, (state) => {
    })
    .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
    })
    .addCase(getUserTransactions.rejected, (state, action) => {
    });