import React, { useEffect, useState } from "react";

import { useMediaQuery, useTheme, Box, Paper, Stack, Typography, Button, Container, Grid2, CardMedia, Divider, TextField } from "@mui/material";
import { alpha } from '@mui/material/styles';

import { useDispatch } from "react-redux";


// Icons
import InsightsIcon from '@mui/icons-material/Insights';
import GroupsIcon from '@mui/icons-material/Groups';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ForwardIcon from '@mui/icons-material/Forward';


// Services
import { isEmail, isNullOrEmpty, checkPassword, verifyPhoneNumber } from "../services/utilities";

// Components
import LandingHeader from "../components/Header";
import { PhoneNumberInput, FeedBack } from "../components/CustomComponents";
import { PasswordField } from "../components/LoginRegister";

// redux
import { registerUser } from "../redux/slices/user-slice";


// Images
// const landing_bg = require("../assets/landing_bg.mov");
// const ski_vid = require("../assets/ski_jump.mp4");
const landing_bg = require("../assets/landing_bg.webp");
const ski_vid = require("../assets/ski_jump.webp");

const da_3 = require("../assets/da_3.jpeg");

const sm_2 = require("../assets/sm_2.jpeg");

const tf_2 = require("../assets/tf_2.jpeg");



// constants
const contactFormBGBlack = alpha('#121212', .3);
const footerBlackBG = alpha('#121212', .5);

const alphaWhite = alpha('#ffffff', .9);

export default function Landing() {
  return (
    <>
      {/* <CardMedia src={`${data_1}`} component="img" autoPlay loop muted controls={false} sx={{ */}
      <CardMedia src={`${landing_bg}`} component="img" autoPlay loop muted controls={false} sx={{
        pointerEvents: "none",
        position: "fixed",
        top: "50%",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        width: "auto",
        height: "auto",
        transform: "translateX(-50%) translateY(-50%);",
        zIndex: -1
      }}
      />
        <LandingHeader />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box>
            <ContactForm />
          </Box>
          <Box bgcolor={alphaWhite} sx={{ backdropFilter: "blur(10px)" }}>
            <BusinessServices />
          </Box>
          <Box>
            <FlyerOrigins />
          </Box>
        </Box>
    </>
  );
}

const landing_text = "...we do more than just deliver leads—we offer a fully managed, end-to-end service that ensures every lead is carefully targeted and primed for your law firm to engage. We’ve developed a new, proprietary mechanism that takes the guesswork out of lead generation, ensuring higher relevance and faster engagement. By leveraging our marketing expertise and this cutting-edge system, we make it easy for your firm to scale and focus on growth with confidence. "


const ContactForm = () => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [feedBack, setFeedback] = useState({ passed: null, comp: null });

  useEffect(() => {
    if (isNullOrEmpty(firstName) || isNullOrEmpty(lastName) || isNullOrEmpty(businessName) || !verifyPhoneNumber(phoneNumber) || !isEmail(email) || !checkPassword(password)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }


  }, [firstName, lastName, businessName, email, phoneNumber, password]);

  const submitClick = async () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      business_name: businessName,
      email: email,
      phone: phoneNumber,
      password: password
    };
    try {
      setSubmitDisabled(true);
      await dispatch(registerUser(payload)).unwrap();
      // setFormReceived(true);
    } catch (exception) {
      let msg = "Registeration failed. Please try again";
      if (exception.detail == "email already registered") {
        msg = "Email already registered, please sign in";
      }
      setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
      setSubmitDisabled(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" py={{ xs: 5, sm: 5, md: 20, lg: 25 }}  sx={{ bgcolor: contactFormBGBlack, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", backdropFilter: "blur(3px)",  height: "100%",  }}>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" px={2} maxWidth="1870px">
        <Grid2 container spacing={10}>
          <Grid2 size={{xs: 12, lg: 7}}>
            <Stack direction="column">
              <Typography variant="h5" fontSize="1.25rem" color="gold" fontWeight="700" textAlign={{xs: "center", md: "left"}}>Over 40 Years of Elite Excellence</Typography>
              <Typography fontSize="3rem" color="white" mb={3} fontWeight="700" textAlign={{xs: "center", md: "left"}}>In This Age of Technology...</Typography>
              <Typography variant="h5" fontSize="1.75rem" color="white" >{landing_text}</Typography>
            </Stack>
          </Grid2>
          <Grid2 size={{xs: 12, lg: 5}} p={2}>
            <Paper sx={{ borderRadius: "20px", bgcolor: "#212121" }}>
              <Stack spacing={2} py={2} px={2}>
                <Typography fontSize="3rem" color="gold" fontWeight="700" textAlign={{xs: "center", md: "left"}}>Elevate Your Potential</Typography>
                <Divider flexItem sx={{ borderBottomWidth: 'medium', my: 1, borderColor: "gold", mb: 3 }}/>
                <Stack direction="row" spacing={1}>
                  <TextField name="firstName" fullWidth label={<Typography fontSize="1rem" fontWeight="600" px={1}>First Name*</Typography>} variant="standard" onChange={(e) => setFirstName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
                  <TextField name="lastName" fullWidth label={<Typography fontSize="1rem" fontWeight="600" px={1}>Last Name*</Typography>} variant="standard" onChange={(e) => setLastName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
                </Stack>
                <TextField name="desc"  variant="standard" label={<Typography fontSize="1rem" fontWeight="600" px={1}>Business Name*</Typography>} onChange={(e) => setBusinessName(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
                <PhoneNumberInput setPhoneNumber={setPhoneNumber} />
                <TextField name="email"  label={<Typography fontSize="1rem" fontWeight="600" px={1}>Email*</Typography>} variant="standard" onChange={(e) => setEmail(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
                <PasswordField name="password" showRequirements={true} label={<Typography fontSize="1rem" fontWeight="600" px={1}>Password*</Typography>} variant="standard" onChange={(e) => setPassword(e.target.value)} sx={{ bgcolor: "white", borderRadius: ".2rem", p: 1 }} />
                <Button variant="contained" size="large" disabled={submitDisabled} fullWidth onClick={submitClick}
                  sx={{
                    fontSize: "1.25rem", borderRadius: "10px", alignSelf: "center", height: "70px", boxShadow: '-2px 2px 0 black', fontWeight: "600", bgcolor: "#8bc34a", color: "black",
                    "&:disabled": {
                      bgcolor: "#ff7961",
                      color: "black"
                    }
                  }}>
                    <Stack direction="row" spacing={1}><Box>Get your leads</Box><ForwardIcon fontSize="large"/></Stack>
                </Button>
                {feedBack.passed !== null && feedBack.comp}
              </Stack>
            </Paper>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}


const BusinessServices = () => {
  const theme = useTheme();
  const bp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box display="flex" justifyContent="center" >
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Grid2 container spacing={15}>
          <Stack direction={bp ? "row" : "column-reverse"} spacing={3}>
            <Grid2 size={{xs: 12, md: 6 }}>
              <Box sx={{ maxHeight: "550px", maxWidth: "550px", width: "auto", height: "auto", }}>
                <Paper elevation={24}>
                <CardMedia component="img"  image={da_3} alt="logo"/>
                </Paper>
              </Box>
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}} >
              <Stack direction="column" spacing={2}>
                <Box>
                  <Stack direction="row" spacing={1} mb={1}><AdsClickIcon fontSize="medium"/><Typography fontSize="1.2rem" fontWeight="700" color="midnightblue">Pay-Per-Click Leads</Typography></Stack>
                  <Typography variant="h4">Improve your ROI with Direct Response Marketing</Typography>
                </Box>
                <Typography variant="subtitle1" fontSize="1.1rem">Our Direct Response Marketing service is designed to generate immediate, tangible, and measurable results for our clients. We employ a two-step lead generation strategy that isolates and targets specific market segments, ensuring that our campaigns reach the most relevant audience. Each strategy is tailor-fitted to the client's niche, industry, and demographic, maximizing the effectiveness of our marketing efforts. </Typography>
              </Stack>
            </Grid2>
          </Stack>

          <Stack direction={bp ? "row" : "column"} spacing={3}>
            <Grid2 size={{xs: 12, md: 6}} >
              <Stack direction="column" spacing={2}>
                <Box>
                  <Stack direction="row" spacing={1} mb={1}><InsightsIcon fontSize="medium"/><Typography fontSize="1.2rem" fontWeight="700" color="midnightblue">Generating Organic Growth</Typography></Stack>
                  <Typography variant="h4">Driving Organic Traffic For Your Business</Typography>
                </Box>
                <Typography variant="subtitle1" fontSize="1.1rem">We specialize in crafting compelling and direct messaging that captures attention and drives engagement. Our professionally structured content is systematically designed to achieve high conversion rates, leveraging proven strategies to maximize the effectiveness of every word. By focusing on precision and clarity, we create copy that not only communicates the client's message but also motivates the target audience to take immediate action, thereby delivering measurable results and a significant return on investment.</Typography>
              </Stack>
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}}>
              <Box ml={bp ? 5 : 0} sx={{ maxHeight: "550px", maxWidth: "550px", width: "auto", height: "auto", }}>
                <Paper elevation={24}>
                <CardMedia component="img"  image={tf_2} alt="logo"/>
                </Paper>
              </Box>
            </Grid2>
          </Stack>

          <Stack direction={bp ? "row" : "column-reverse"} spacing={3}>
            <Grid2 size={{xs: 12, md: 6}}>
              <Box sx={{ maxHeight: "550px", maxWidth: "550px", width: "auto", height: "auto", }}>
                <Paper elevation={24}>
                <CardMedia component="img"  image={sm_2} alt="logo"/>
                </Paper>
              </Box>
            </Grid2>
            <Grid2 size={{xs: 12, md: 6}} >
              <Stack direction="column" spacing={2}>
                <Box>
                  <Stack direction="row" spacing={1} mb={1}><GroupsIcon fontSize="medium"/><Typography fontSize="1.2rem" fontWeight="700" color="midnightblue">Leverage Social Media</Typography></Stack>
                  <Typography variant="h4">Social Media Utilization and Growth</Typography>
                </Box>
                <Typography variant="subtitle1" fontSize="1.1rem">Our experts will grow your business’s social media presence by creating targeted content that engages your audience and drives interaction. Using data analytics and strategic ads, we ensure your brand reaches the right people, increasing visibility and customer engagement. In today’s digital age, a strong social media presence is crucial for brand awareness and staying competitive.</Typography>
              </Stack>
            </Grid2>
          </Stack>
        </Grid2>
      </Container>
    </Box>
  );
}



const FlyerOrigins = () => {
  const sent_1 = 'Flyers Group Marketing originated from a rich legacy of mentorship and talent cultivation. What began as a world-class gymnastics center, producing X-Games skiers, renowned Cirque du Soleil performers, professional Hollywood stuntmen, a Barnum and Bailey circus trapeze flyer, and even an Olympian at the 1976 Montreal Olympics, has since evolved into an elite-level digital marketing company. This same commitment to nurturing excellence now drives our approach to empowering brands and businesses in the digital space.'
  const sent_2 = 'Attitude Is Everything. We know how to turn the ordinary into the extraordinary.';
  const sent_3 = 'At Flyers Group Marketing, we apply these same principles by focusing on the unique potential of each brand we work with. We believe that, like Olympians, standout brands are not created through force but through careful cultivation. Our goal is to help businesses grow their digital presence by leveraging a network of world-class talent and applying tailored strategies that maximize each brand’s impact.';
  const sent_4 = `Digital marketing is experiencing exponential growth in today's rapidly advancing technological landscape. Our team of experts in technology, marketing, psychology, and the arts are unmatched in the industry, all driven by the same discipline and mindset we instilled in our former world-class athletes and professionals—many of whom continue to excel in their fields today. This commitment to excellence empowers us to deliver innovative and impactful marketing solutions for our clients.`;


  return (
    <Box position="relative" sx={{  height: "100%" }}>
      <CardMedia src={`${ski_vid}`} component="img" autoPlay loop muted controls={false} sx={{
        objectFit: "cover",
        top: "50%",
        left: "50%",
        minHeight: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        width: "auto",
        height: "auto",
        position: "absolute",
        transform: "translateX(-50%) translateY(-50%);",
        zIndex: -1,
        // 
        }} />
      <Box sx={{ position: "absolute", objectFit: "cover", bgcolor: footerBlackBG, minHeight: "100%",minWidth: "100%", zIndex: -1,}}/>
      <Container maxWidth="md" sx={{ pt: {xs: 10, xl: 15}, pb: {xs: 7, xl: 45}, }}>
        <Grid2 container>
          <Grid2 size={{ xs: 12, md: 12 }}>
            <Divider flexItem orientation="vertical" sx={{ borderBottomWidth: 'medium', my: 1, borderColor: "gold", mb: 3 }}/>
            <Typography fontSize="3rem" color="gold" fontWeight="700" textAlign={{xs: "center", md: "left"}}>The Flyer's Promise</Typography>
            <Stack direction="column" spacing={3} pt={2}>
              <Typography fontSize="1.5rem" color="white">{sent_1}</Typography>
              <Typography fontSize="1.5rem" color="white">{sent_2}</Typography>
              <Typography fontSize="1.5rem" color="white">{sent_3}</Typography>
              <Typography fontSize="1.5rem" color="white">{sent_4}</Typography>
            </Stack>
            <Divider flexItem orientation="vertical" sx={{ borderBottomWidth: 'medium', my: 1, borderColor: "gold", mt: 3 }}/>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}

