import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Typography, Switch, Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions  } from "@mui/material";

import { updateAccountFrozen, updateRechargeThreshold } from "../redux/slices/user-slice";
import { getClientSubscription } from "../redux/slices/user-slice";
import { rechargeAccount } from "../redux/slices/payment-slice";

import { showDollars } from "../services/utilities";

export const FreezeAccount = () => {
  const dispatch = useDispatch();

  const { is_frozen } = useSelector((state) => state.user.user_subscription);
  const [isFrozen, setIsFrozen] = useState(is_frozen);

  useEffect(() => {
    if (isFrozen != is_frozen) {
      setIsFrozen(is_frozen);
    }
  }, [is_frozen])
  const updateFrozen = async (status) => {
    try {
      const payload = {
        is_frozen: status,
      }
      await dispatch(updateAccountFrozen(payload)).unwrap();
      await dispatch(getClientSubscription()).unwrap();
    } catch (exception) {
      
    }
  }

  const handleIsFrozen = (event) => {
    setIsFrozen(event.target.checked);
    updateFrozen(event.target.checked);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Switch
        checked={isFrozen}
        onChange={handleIsFrozen}
      />
      <Typography fontSize="1.2rem" mt={2} textTransform="capitalize">{isFrozen ? "Unpause" : "Pause"} Incoming Leads</Typography>
    </Stack>
  )
}

export const SetAutoReplenishThreshold = () => {
  const dispatch = useDispatch();
  const { recharge_threshold, credit_balance } = useSelector((state) => state.user.user_subscription);
  const { legal_categories } = useSelector((state) => state.user);
  const { price_list } = useSelector((state) => state.lead);
  const [minAmount, setMinAmount] = useState(0);
  const [isOn, setIsOn] = useState(recharge_threshold > 0 ? true : false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (legal_categories.length > 0 && price_list.length > 0) {
      let currMin = 0;
      legal_categories.forEach((cat) => {
        const price = price_list.find(p => p.category_id == cat).price;
        if (price > currMin){
          currMin = price;
        }
      })
      setMinAmount(currMin);
    }

  }, [legal_categories, price_list]);
  
  useEffect(() => {
    if (recharge_threshold > 0) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [recharge_threshold])

  const updateIsOn = async () => {
    try {
      const payload = {
        threshold_amount: isOn ? minAmount : null,
      }
      await dispatch(updateRechargeThreshold(payload)).unwrap();
      await dispatch(getClientSubscription()).unwrap();
    } catch (exception) {
      
    }
  }
  useEffect(() => {
    if(isOn && recharge_threshold == null) {
      updateIsOn();
    } else if (isOn == false && recharge_threshold > 0 ) {
      updateIsOn();
    }
  }, [isOn])

  const handleIsOn = (event) => {
    setIsOn(event.target.checked);
  };

  const rechargeAction = async () => {
    try {
      await dispatch(rechargeAccount()).unwrap();
      await dispatch(getClientSubscription()).unwrap();
    } catch (exception) {
      
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Switch
          checked={isOn}
          onChange={handleIsOn}
        />
        <Stack direction="column">
          <Typography fontSize="1.2rem" mt={2} textTransform="capitalize">Auto Fund Account</Typography>
          <Typography variant="subtitle2"> When balance is under {showDollars(recharge_threshold > 0 ? recharge_threshold : minAmount)}, it'll automatically replenish your current plan</Typography>
        </Stack>
      </Stack>
      {credit_balance <= minAmount && <Stack direction="column" spacing={1} >
        <Button variant="contained" disabled={false} onClick={handleClickOpen} sx={{ bgcolor: "blue", borderRadius: 5, width: "250px", height: "3rem", mt: 2, textTransform: "capitalize", fontWeight: 600 }}>Recharge Account!</Button>
        <Typography variant="subtitle1">Add more funds to your account!</Typography>
      </Stack>}
    </Stack>
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle variant="h4" fontWeight={600} textAlign="center" sx={{ bgcolor: "#1769aa", color: "white" }}>
        {`Recharge your account!`}
      </DialogTitle>
      <DialogContent sx={{ bgcolor: "#1769aa" }}>
        <DialogContentText color="white">
          Fund your account and reset your plan's bonus leads!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ bgcolor: "#1769aa" }}>
        <Button onClick={handleClose} sx={{ color: "white", border: "1px solid white", mr: 3 }} >Go back</Button>
        <Button variant="contained" autoFocus sx={{ boxShadow: "0 0 5px 2px gold"}} onClick={() => {
          handleClose();
          rechargeAction();
        }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

