export function isEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export function verifyPhoneNumber(value) {
    // const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value) {
        return value.length == 10;
    }
    return false;
}


export function isNullOrEmpty(value) {
    if (value) {
      return false;
    }
    return true;
}


export function checkPassword(value) {
    if (value) {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
        return re.test(value);
    }
    return false;
}

export function decodeJwt(val, section) {
    if (val && (section === 0 || section === 1)) {
        return JSON.parse(atob(val.split(".")[section]));
    }
    return null;
}

export function min8Characters(value) {
// const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value) {
        return value.length >= 8;
    }
    return false;
}

export function hasUpperCase(value) {
    if (value) {
        const re = /(.*[A-Z].*)/;
        return re.test(value);
    }
    return false;
}
  
export function hasLowerCase(value) {
if (value) {
    const re = /(.*[a-z].*)/;
    return re.test(value);
}
return false;
}

export function hasNumber(value) {
    if (value) {
        const re = /(.*\d.*)/;
        return re.test(value);
    }
}

export function showDollars(value, in_cents=true) {
    let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
    }
    let dollarString = new Intl.NumberFormat("en-US", formatting_options);
    if (in_cents) {
        value = value/100
    }
    return dollarString.format(value);
}