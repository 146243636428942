import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import AccountDashboard from "./ui/AccountDashboard";
import ModeratorPanel from "./ui/ModeratorPanel";
import { ROLES } from "./services/constants";

export default function VerifiedRouter() {
  const { roles } = useSelector((state) => state.user);
  let dashboardComp = <AccountDashboard />
  if (roles.includes(ROLES.MODERATOR)) {
    dashboardComp = <ModeratorPanel />

  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={dashboardComp}></Route>
        <Route path="*" element={dashboardComp}></Route>
      </Routes>
    </BrowserRouter>
  );
}
