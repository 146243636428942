import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Landing from "./ui/Landing";
import ResetPasswordPage from "./ui/ResetPasswordPage";


export default function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/reset/:code" element={<ResetPasswordPage />}></Route>
        <Route path="*" element={<Landing />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
